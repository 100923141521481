import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import girlMobile from "../images/girl-mobile.png";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { isObjectEmpty } from "../utils";
import { loginUser } from "../store/slices/userSlice";

export default function Login() {
  const [loginData, setLoginData] = useState({
    email: null,
    password: null,
  });
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let { loginResponse } = useSelector((state) => state.user);
  const handleState = (e) => {
    let temp = { ...loginData };
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    temp[key] = value;
    setLoginData(temp);
  };
  const handleLoginForm = (e) => {
    try {
      // console.log("loginData ::::::::::: ", loginData);
      e.preventDefault();
      let temp = { ...loginData };
      let objKeys = Object.keys(temp);
      for (var k in objKeys) {
        let keyName = objKeys[k];
        let itemName = objKeys[k];
        if (temp[itemName] === null || temp[itemName] === "") {
          toast.warn(`${keyName} is missing`);
          // return console.log(`${keyName} is missing`);
        }
      }
      dispatch(loginUser(temp));
    } catch (err) {
      toast["error"]("Wrong Email or Password.");
      console.log("handleLogin form ::::::::::: ", err);
    }
  };

  useEffect(() => {
    let notValid = isObjectEmpty({ ...loginResponse });
    if (notValid) {
      toast[loginResponse.type](loginResponse.message);
      if (loginResponse.type === "success") {
        navigate("/"); // Or navigate(-1, { replace: true }) depending on the logic
      }
    }
  }, [loginResponse, navigate]);

  return (
    <div className="overflow-hidden">
      <div
        className="container pb-4"
        style={{ maxWidth: "516px", marginBottom: "50px", marginTop: "50px" }}
      >
        <h2 className="fw-bold ps-3 mb-4"> LOGIN </h2>
        <div className="d-flex flex-nowrap bg-white">
          <form
            className="rounded shadow col-12 p-4"
            onSubmit={(e) => {
              handleLoginForm(e);
            }}
          >
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-dark fs-5">
                Email
              </label>
              <input
                type="email"
                onChange={(e) => {
                  handleState(e);
                }}
                className="form-control py-3 fs-5"
                id="email"
                placeholder="mymail@gmail.com"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label text-dark fs-5">
                Password
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="password"
                  onChange={(e) => {
                    handleState(e);
                  }}
                  className="form-control py-3 fs-5 col-12"
                  id="password"
                />
                <i
                  role="button"
                  className="fa fa-eye"
                  toggletargetId="password"
                  toggleShow="true"
                  onClick={togglePassword}
                  style={{
                    position: "relative",
                    right: "40px",
                    color: "#5c5c5c",
                  }}
                ></i>
              </div>

              <div className="col-12 d-flex justify-content-end">
                <Link
                  role="button"
                  to="/forgot-password"
                  className="text-decoration-none text-color  fw-semibold"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mb-3 form-check ">
              <input
                type="checkbox"
                className="form-check-input "
                id="rememberMe"
              />
              <label className="form-check-label" htmlFor="rememberMe">
                Remember me
              </label>
            </div>
            <div className="d-flex flex-column align-items-center py-3">
              <div
                role="button"
                className="rounded bg-color text-white py-2 px-4 fs-5 fw-semibold mb-3"
              >
                {" "}
                <button
                  onClick={(e) => {
                    handleLoginForm(e);
                  }}
                  className="none"
                >
                  Login
                </button>{" "}
              </div>
              <span className="fw-semibold">Don't have an account?</span>
              <Link
                className="text-decoration-none fs-5 fw-semibold text-color"
                to="/signup"
              >
                SIGN UP
              </Link>
            </div>
          </form>
          <div className="d-flex align-items-end">
            <img
              className="d-none d-md-block"
              src={girlMobile}
              alt="a vector girl character with a mobile in her hand"
              style={{ position: "relative", left: "-20px", height: "105%" }}
            ></img>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );

  function togglePassword(e) {
    if (e.target.getAttribute("toggleShow") == "true") {
      e.target.setAttribute("toggleShow", "false");
      document.getElementById(e.target.getAttribute("toggletargetId")).type =
        "text";
    } else {
      e.target.setAttribute("toggleShow", "true");
      document.getElementById(e.target.getAttribute("toggletargetId")).type =
        "password";
    }
  }
}
