import React, { useState, useEffect } from "react";
import Logo from "../images/Logo.svg";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdb-react-ui-kit";
import { useAxios } from "../utils/axiosprovider";

export default function AdminLogin() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  /* const [loginData, setLoginData] = useState({
		"email": "Pixominia",
		"password": "Knowledge@!12"
	}); */
  const { adminloginApi } = useAxios();

  const navigate = useNavigate();

  const handleState = (e) => {
    let temp = { ...loginData };
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    temp[key] = value;
    setLoginData(temp);
  };
  const handleLoginForm = (e) => {
    try {
      e.preventDefault();
      let temp = { ...loginData };
      let objKeys = Object.keys(temp);
      for (var k in objKeys) {
        let keyName = objKeys[k];
        let itemName = objKeys[k];
        if (temp[itemName] === null || temp[itemName] === "") {
          return console.log(`${keyName} is missing`);
        }
      }
      adminloginApi(temp);
    } catch (err) {
      console.log("handleLogin form ::::::::::: ", err);
    }
  };

  return (
    <div className="container">
      <MDBContainer className="" style={{ marginTop: "250px" }}>
        <form
          onSubmit={(e) => {
            handleLoginForm(e);
          }}
        >
          <MDBRow className="g-5 justify-content-center">
            <MDBCol md="5">
              <MDBCard>
                <MDBCardBody className="d-flex flex-column">
                  <Link to="/">
                    <img
                      className="pt-1"
                      src={Logo}
                      style={{ height: "44px" }}
                      alt="logo"
                    />
                  </Link>

                  <h5
                    className="fw-normal my-4 "
                    style={{ letterSpacing: "1px" }}
                  >
                    Sign into your account
                  </h5>

                  <MDBInput
                    value={loginData.email ?? ""}
                    id="email"
                    onChange={(e) => {
                      handleState(e);
                    }}
                    wrapperClass="mb-4"
                    label="Email address"
                    size="lg"
                  />
                  <MDBInput
                    value={loginData.password}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    wrapperClass="mb-4"
                    label="Password"
                    id="password"
                    size="lg"
                  />

                  <MDBBtn className="mb-4 py-3 px-5" color="dark" type="submit">
                    Login
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBContainer>
    </div>
  );
}
