import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAxios } from "../utils/axiosprovider";
import { toast } from "react-toastify";
export default function UserProfile() {
  const [selectedFile, setSelectedFile] = useState();
  const [user, setuser] = useState();
  const [preview, setPreview] = useState();
  const [profilepic, setprofilepic] = useState();
  const { updateProfile } = useAxios();
  const [formData, setFormData] = useState({
    FirstName: null,
    LastName: null,
    EmailAddress: null,
    Phone: null,
    Birthday: null,
    oldPassword: null,
    newPassword: null,
  });

  useEffect(() => {
    var userdata = JSON.parse(window.localStorage.getItem("user"));
    // console.log(userdata);
    var userinfo = {
      FirstName: userdata?.first_name ?? "",
      LastName: userdata?.last_name ?? "",
      EmailAddress: userdata?.email ?? "",
      Phone: userdata?.mobileno ?? "",
      Birthday: userdata?.dob ?? "",
      oldPassword: null,
      newPassword: null,
    };
    setprofilepic(userdata?.profile_image);
    // console.log(`https://api.pixominia.com/api/v1/${userdata?.profile_image}`);
    // setPreview(`https://pixominia.com:9000/${userdata?.profile_image}`);
    setFormData(userinfo);
    setuser(userdata);

    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const customstyle = {
    display: "block",
    width: "100%",
    padding: "0.875rem 1.125rem",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1",
    color: "#69707a",
    backgroundColor: " #fff",
    backgroundClip: "padding-box",
    border: "1px solid #c5ccd6",
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    borderRadius: "0.35rem",
    transition: "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
  };

  const handleState = (e) => {
    let temp = { ...formData };
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    temp[key] = value;
    setFormData(temp);
  };
  const handleupdateuserForm = (e) => {
    try {
      e.preventDefault();
      let temp = { ...formData };

      // let objKeys = Object.keys(formData)
      // for (var k in objKeys) {
      //   let keyName = objKeys[k]
      //   let itemName = objKeys[k]
      //   if (temp[itemName] === null || temp[itemName] === "") {
      //     toast["error"](`${keyName} is missing`);
      //     return console.log(`${keyName} is missing`);
      //   }
      // }
      if (temp.newPassword !== null && temp.newPassword !== "") {
        if (temp.oldPassword === null || temp.oldPassword === "") {
          toast["error"](`oldPassword is missing`);
          return console.log(`oldPassword is missing`);
        } else {
          updateProfile(temp, selectedFile);
        }
      } else {
        updateProfile(temp, selectedFile);
      }
    } catch (err) {
      console.log("handleLogin form ::::::::::: ", err);
    }
  };
  return (
    <form
      onSubmit={(e) => {
        handleupdateuserForm(e);
      }}
    >
      <div
        className="container-xl d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "",
          borderRadius: "5px",
          marginTop: "20px",
          color: "#69707a",
        }}
      >
        <div className="row col-xl-6">
          {/* <div className="col-xl-4">
            <div
              className="card mb-4 mb-xl-0"
              style={{
                fontWeight: "500",
                boxShadow: "0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%)",
              }}
            >
              <div
                className="card-header"
                style={{
                  fontWeight: "500",
                  padding: " 1rem 1.35rem",
                  marginBottom: "0",
                  backgroundColor: "rgba(33, 40, 50, 0.03)",
                  borderBottom: " 1px solid rgba(33, 40, 50, 0.125)",
                }}
              >
                Profile Picture
              </div>
              <div className="card-body text-center">
                <img
                  className="img-account-profile rounded-circle mb-2"
                  style={{
                    borderRadius: "50% !important",
                    width: "10rem",
                    height: "10rem",
                  }}
                  src={
                    selectedFile == null
                      ? profilepic != null
                        ? `http://pixominia.com:9000/${profilepic}`
                        : "http://bootdey.com/img/Content/avatar/avatar7.png"
                      : preview
                  }
                  alt=""
                />

                
                <div className="small font-italic text-muted mb-4">
                  JPG or PNG no larger than 5 MB
                </div>
                <div className="d-flex justify-content-center">
                  <div className="btn btn-info btn-rounded">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => {
                        let file = e.target.files[0];
                        let fileType = file.type; // image/jpeg
                        let fileSize = file.size; // 5MB

                        if (fileSize > 5 * 1000000) {
                         
                          alert(
                            `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
                              fileSize / 1000000
                            }MB only`
                          );
                          // return;
                        } else {
                          setSelectedFile(e.target.files[0]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <div className="d-flex py-3 fw-semibold">
              <Link className=" fs-4 text-center text-decoration-none text-secondary py-1">
                {" "}
                User Profile{" "}
              </Link>
            </div>
            <div
              className="card mb-4"
              style={{
                fontWeight: "500",
                boxShadow: "0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%)",
              }}
            >
              <div
                className="card-header"
                style={{
                  padding: " 1rem 1.35rem",
                  marginBottom: "0",
                  backgroundColor: "rgba(33, 40, 50, 0.03)",
                  borderBottom: " 1px solid rgba(33, 40, 50, 0.125)",
                }}
              >
                Account Details
              </div>
              <div className="card-body">
                <div className="card-body text-center">
                  <img
                    className="img-account-profile rounded-circle mb-2"
                    style={{
                      borderRadius: "50% !important",
                      width: "10rem",
                      height: "10rem",
                    }}
                    src={
                      selectedFile == null
                        ? profilepic != null
                          ? `https://api.pixominia.com/api/v1/${profilepic}`
                          : "http://bootdey.com/img/Content/avatar/avatar7.png"
                        : preview
                    }
                    alt=""
                  />
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="FirstName">
                      First name
                    </label>
                    <input
                      className="form-control"
                      style={customstyle}
                      id="FirstName"
                      type="text"
                      value={formData?.FirstName}
                      placeholder="Enter your first name"
                      onChange={(e) => {
                        handleState(e);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="LastName">
                      Last name
                    </label>
                    <input
                      className="form-control"
                      style={customstyle}
                      value={formData?.LastName}
                      id="LastName"
                      type="text"
                      placeholder="Enter your last name"
                      onChange={(e) => {
                        handleState(e);
                      }}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="small mb-1" htmlFor="EmailAddress">
                    Email address
                  </label>
                  <input
                    className="form-control"
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "0.875rem 1.125rem",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      lineHeight: "1",
                      color: "#69707a",
                      backgroundColor: " #fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #c5ccd6",
                      webkitAppearance: "none",
                      mozAppearance: "none",
                      appearance: "none",
                      borderRadius: "0.35rem",
                      transition:
                        "border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out",
                    }}
                    id="EmailAddress"
                    value={formData?.EmailAddress}
                    type="email"
                    onChange={(e) => {
                      handleState(e);
                    }}
                    placeholder="Enter your email address"
                  />
                </div>

                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="Phone">
                      Phone number
                    </label>
                    <input
                      className="form-control"
                      style={customstyle}
                      id="Phone"
                      type="tel"
                      value={formData?.Phone}
                      placeholder="Enter your phone number"
                      onChange={(e) => {
                        handleState(e);
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="Birthday">
                      Birthday
                    </label>
                    <input
                      className="form-control"
                      style={customstyle}
                      id="Birthday"
                      type="text"
                      value={formData?.Birthday}
                      name="birthday"
                      placeholder="dd/mm/yyyy"
                      onChange={(e) => {
                        handleState(e);
                      }}
                    />
                  </div>
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="oldPassword">
                      Old Password
                    </label>
                    <input
                      className="form-control"
                      style={customstyle}
                      id="oldPassword"
                      value={formData?.oldPassword}
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        handleState(e);
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="newPassword">
                      New Password
                    </label>
                    <input
                      className="form-control"
                      style={customstyle}
                      id="newPassword"
                      value={formData?.newPassword}
                      type="password"
                      placeholder=""
                      onChange={(e) => {
                        handleState(e);
                      }}
                    />
                  </div>
                </div>

                <button className="btn btn-info text-white " type="submit">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
