import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function ThankYou() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header className="mt-5"/>
      <div className="container my-5 text-center flex-grow-1">
        <h1 className="display-4 text-info fw-semibold">Thank You!</h1>
        <p className="lead text-muted">
          Your request has been submitted successfully. Our team will start
          working on it and get back to you shortly.
        </p>
        <Link to="/" className="btn btn-info btn-lg text-white mt-4">
          Go to Home
        </Link>
      </div>
      <Footer />
    </div>
  );
}
