import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  registerUserApi,
  loginUserApi,
  otpUserApi,
  forgotUserApi,
  changeUserPasswordApi,
} from "../../api/userapi.js";
let initialState = {
  loading: false,
  showRegisterLoader: false,
  user: {},
  isLoggedIn: false,
  response: {},
  loginResponse: {},
};

export const loginUser = createAsyncThunk("user/loginUser", async (data) => {
  try {
    let res = await loginUserApi(data);
    if (res.status === 200) {
      return {
        type: "success",
        user: res.data.data,
        token: res.data.token,
        message: res.data.message,
      };
    } else if (res.status === 409) {
      return {
        type: "info",
        message: res.data.message,
      };
    } else if (res.status === 500) {
      return {
        type: "error",
        message: res.data.message,
      };
    }
  } catch (err) {
    console.log("error in login user", err);
    return {
      type: "error",
      message: err.data.message,
    };
  }
});

export const otpUser = createAsyncThunk("user/otpUser", async (data) => {
  try {
    let res = await otpUserApi(data);
    if (res.status === 200) {
      return {
        type: "success",
        user: res.data.data,
        token: res.data.token,
        message: res.data.message,
      };
    } else if (res.status === 409) {
      return {
        type: "info",
        message: res.data.message,
      };
    } else if (res.status === 500) {
      return {
        type: "error",
        message: res.data.message,
      };
    }
  } catch (err) {
    console.log("Error in sending otp", err);
    return {
      type: "error",
      message: err.data.message,
    };
  }
});

export const forgotUser = createAsyncThunk("user/forgotUser", async (data) => {
  try {
    let res = await forgotUserApi(data);
    if (res.status === 200) {
      return {
        type: "success",
        user: res.data.data,
        token: res.data.token,
        message: res.data.message,
      };
    }
  } catch (err) {
    console.log("Error in sending reset link", err);
    return {
      type: "error",
      message: err.data.message,
    };
  }
});

export const changeUserPassword = createAsyncThunk(
  "user/changeUserPassword",
  async (data) => {
    try {
      let res = await changeUserPasswordApi(data);
      if (res.status === 200) {
        return {
          type: "success",
          user: res.data.data,
          token: res.data.token,
          message: res.data.message,
        };
      }
    } catch (err) {
      console.log("Error in changing new password", err);
      return {
        type: "error",
        message: err.data.message,
      };
    }
  }
);

export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (data) => {
    const res = await registerUserApi(data);
    if (res.status === 201) {
      return {
        type: "success",
        message: res.data.message,
      };
    } else if (res.status === 409) {
      return {
        type: "info",
        message: res.data.message,
      };
    } else if (res.status === 500) {
      return {
        type: "error",
        message: res.data.message,
      };
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (action.payload.success) {
        state.user = action.payload.data;
      }
    },
    setResponse: (state, action) => {
      if (action.payload.empty) {
        state.response = false;
      }
    },
    setLoginResponse: (state, action) => {
      if (action.payload.empty) {
        state.loginResponse = {};
        state.isLoggedIn = false;
        window.localStorage.setItem("accessToken", "");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.showLoggedInLoader = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.showLoggedInLoader = false;
      if (action?.payload?.type === "success") {
        state.user = action?.payload?.user[0];
        state.isLoggedIn = true;
        window.localStorage.setItem("accessToken", action.payload.token);
        window.localStorage.setItem(
          "user",
          JSON.stringify(action.payload.user[0])
        );
      }
      state.loginResponse = {
        type: action?.payload?.type,
        message: action?.payload?.message,
      };
    });
    builder.addCase(registerUser.pending, (state, action) => {
      state.showRegisterLoader = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.showRegisterLoader = false;
      state.response = action.payload;
    });
    builder
      .addCase(otpUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(otpUser.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.message = action.payload.message;
      })
      .addCase(otpUser.rejected, (state, action) => {
        state.status = "error";
        state.message = action.payload.message;
      });
    builder
      .addCase(forgotUser.pending, (state) => {
        state.status = "loading";
        state.showForgotPasswordLoader = true;
      })
      .addCase(forgotUser.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.showForgotPasswordLoader = false;
        state.message = action.payload.message;
      })
      .addCase(forgotUser.rejected, (state, action) => {
        state.status = "error";
        state.message = action.payload.message;
      });
    builder
      .addCase(changeUserPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.message = action.payload.message;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.status = "error";
        state.message = action.payload.message;
      });
  },
});

export default userSlice;
