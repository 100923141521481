import React from "react";
import { Link } from "react-router-dom";
import ManThinking from "../images/man-thinking.png";
import GroupBulb from "../images/group-bulb.png";

export default function AboutUs() {
  return (
    <div className="bg-white" style={{ margin: "0 250px" }}>
      <div className="container ">
        <div className="fs-1 fw-bold text-center pt-5"> About Us </div>
        <div className="bg-info col-12 d-flex my-5">
          <div className="col-12 ps-5 pt-5 fs-5 text-light">
            <h3 className="fs-1 fw-bold">
              Welcome To <span>Pixominia</span>
            </h3>
            <div className="py-3">
              <p>
                Welcome to<span> Pixominia </span>
                <span>
                  your ultimate destination for unlocking the magic of animated
                  creativity.
                </span>{" "}
                At Pixominia, we believe that every idea, no matter how big or
                small, deserves to be brought to life with a touch of animation
                . Our mission is to empower creators like you with the tools to
                effortlessly craft captivating <br /> visual stories.
              </p>
            </div>
            {/* <Link className="text-decoration-none border-bottom px-1">
              See our candidates <i className="bi bi-arrow-right"></i>
            </Link> */}
          </div>
          {/* <div className="col-6 d-none justify-content-end d-lg-flex">
            <div
              className="rounded-circle"
              style={{
                height: "300px",
                width: "300px",
                backgroundImage:
                  "url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA5TOhiJDxKO3ENghbXH8DcZP7Irc7LwlcWw&usqp=CAU')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div
              className="align-self-end"
              style={{ position: "absolute", transform: "translateX(-100%)" }}
            >
              <div
                className="rounded-circle"
                style={{
                  height: "200px",
                  width: "200px",
                  backgroundImage:
                    "url('https://newprofilepic2.photo-cdn.net//assets/images/article/profile.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="" style={{ background: "#FFF7ED" }}>
        <div className="container d-flex justify-content-between flex-wrap fs-4 py-3">
          <div className="my-3 my-sm-0 px-2 col-12 col-sm-6 col-lg-3">
            <div className="text-center">
              <b className="fs-1">
                {" "}
                300K+ <br />{" "}
              </b>
              Vetted candidates
            </div>
          </div>
          <div className="my-3 my-sm-0 px-2 col-12 col-sm-6 col-lg-3">
            <div className="text-center">
              <b className="fs-1">
                {" "}
                3200 <br />{" "}
              </b>
              Data Points
            </div>
          </div>
          <div className="my-3 my-sm-0 px-2 col-12 col-sm-6 col-lg-3">
            <div className="text-center">
              <b className="fs-1">
                {" "}
                24 <br />{" "}
              </b>
              Tech Stacks
            </div>
          </div>
          <div className="my-3 my-sm-0 px-2 col-12 col-sm-6 col-lg-3">
            <div className="text-center">
              <b className="fs-1">
                {" "}
                321 <br />{" "}
              </b>
              Live Project
            </div>
          </div>
        </div>
      </div> */}
      <div className="container d-flex flex-wrap flex-fill my-5">
        <img
          src={GroupBulb}
          style={{ height: "350px", margin: "100px 50px 0 0 " }}
          alt="Why Choose Pixominia"
        />
        <div className="row align-items-center col-12 col-lg-6 p-1 ps-5">
          <div className="fw-bold text-info">
            {/* HOW IT WORKS <br /> */}
            <h3 className="fs-1 fw-bold text-dark">What Sets Us Apart</h3>
          </div>
          <div className="">
            <div className="d-flex py-3">
              <div className="text-info fw-bold fs-3">01</div>
              <div className="ms-4 fs-5">
                <h3> Free Animated Assets: </h3>
                Dive into our treasure trove of high-quality animated videos,
                dynamic transitions, and vibrant backgrounds—all available for
                free. We believe in democratizing creativity, making premium
                resources accessible to everyone.
              </div>
            </div>
            <hr />
            <div className="d-flex py-3">
              <div className="text-info fw-bold fs-3">02</div>
              <div className="ms-4 fs-5">
                <h3> Instant Inspiration:</h3>
                Pixominia is your shortcut to elevating your content. Whether
                you're a seasoned creator or just starting, our curated
                collection of assets is designed to spark inspiration and
                accelerate your creative journey.
              </div>
            </div>
            <hr />
            <div className="d-flex py-3">
              <div className="text-info fw-bold fs-3">03</div>
              <div className="ms-4 fs-5">
                <h3> Versatility at Your Fingertips:</h3>
                From animated objects that add flair to your scenes to seamless
                transitions that enhance storytelling, and vivid backgrounds
                that set the mood—Pixominia provides the variety you need to
                express your unique vision.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-wrap flex-fill my-5">
        <div className="row align-items-center col-12 col-lg-6 p-1 ps-5">
          <div className="fw-bold text-info">
            <h3 className="fs-1 fw-bold text-dark">Why Choose Pixominia</h3>
          </div>
          <div className="d-flex py-3">
            <div className="text-info fw-bold fs-3">01</div>
            <div className="ms-4 fs-5">
              <h3> User-Friendly Platform: </h3>
              Our website is designed with simplicity in mind. Navigate
              effortlessly, find the perfect assets, and download them with
              ease. Your creative process should be smooth, and Pixominia is
              here to make that happen.
            </div>
          </div>
          <hr />
          <div className="d-flex py-3">
            <div className="text-info fw-bold fs-3">02</div>
            <div className="ms-4 fs-5">
              <h3> Diverse and Inclusive:</h3>
              We celebrate diversity in creativity. Our collection spans a wide
              range of styles and themes, ensuring there's something for every
              project and every creator.
            </div>
          </div>
          <hr />
        </div>

        <img
          src={ManThinking}
          style={{ height: "500px", margin: "0 0 0 100px" }}
          alt="Why Choose Pixominia"
        />
      </div>
      <div className="container d-flex flex-wrap flex-fill my-5">
        <div className="row align-items-center col-12 col-lg-6 p-1 ps-5">
          <h3 className="fs-1 fw-bold text-dark">Request Your Need</h3>
          <div className="fs-5 mt-4">
            We celebrate diversity in creativity. Our collection spans a wide
            range of styles and themes, ensuring there's something for every
            project and every creator.
          </div>
          <div className="fw-bold text-info fs-4">
            <div className="d-flex py-3"></div>
            How It Works <br />
          </div>
          <div className="d-flex py-3">
            <div className="text-info fw-bold fs-3">01</div>
            <div className="ms-4 fs-5">
              <h3> Submit Your Request:</h3>
              Is there a specific video animation, transition, or background
              you're looking for but can't find in our current collection? Tell
              us about it! Submit your request through the form below.
            </div>
          </div>
          <div className="d-flex py-3">
            <div className="text-info fw-bold fs-3">02</div>
            <div className="ms-4 fs-5">
              <h3> Our Promise:</h3>
              We understand that every project is unique, and sometimes, you
              need that special touch. Once you submit your request, our team
              will work diligently to create the animation you're looking for.
            </div>
          </div>
          <div className="d-flex py-3">
            <div className="text-info fw-bold fs-3">03</div>
            <div className="ms-4 fs-5">
              <h3> Free Within 24 Hours:</h3>
              Yes, you read that right! We're committed to providing you with
              the requested animation absolutely free of charge within 24 hours.
              We believe in the power of swift creativity.
            </div>
          </div>
        </div>
        <div className="row align-items-center col-12 col-lg-6 p-1 ps-5">
          <h3 className="fs-1 fw-bold text-dark">
            Why Choose "Request Your Need"
          </h3>
          <div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdnze-mmrLvP509xvP882ayWQD7fB4rokAqFMqVxL36U6WvZA/viewform?usp=sf_link"
              target="_blank"
              className="btn btn-info text-white btn-lg active "
              role="button"
              aria-pressed="true"
            >
              Request Your Need
            </a>
          </div>
          <div className="fw-bold text-info fs-4">
            How It Works <br />
          </div>
          <div className="d-flex">
            <div className="text-info fw-bold fs-3">01</div>
            <div className="ms-4 fs-5">
              <h3> Tailored to You:</h3>
              We're here to tailor our offerings to your specific requirements.
              Your creativity deserves a platform that listens and adapts.
            </div>
          </div>
          <div className="d-flex py-3">
            <div className="text-info fw-bold fs-3">02</div>
            <div className="ms-4 fs-5">
              <h3> Community Collaboration:</h3>
              Join hands with fellow creators and be part of a community that
              collaborates to enhance the creative experience for everyone.
            </div>
          </div>
          <div className="d-flex py-3">
            <div className="ms-4 fs-5">
              <h3 className="text-info fw-bold fs-3">
                {" "}
                Ready to Make Your Request?
              </h3>
              Use the simple form button to submit your request, and let us
              bring your creative vision to life.
            </div>
          </div>
        </div>

        {/* <img
          src={ManThinking}
          style={{ height: "500px", margin: "0 0 0 100px" }}
          alt="Why Choose Pixominia"
        /> */}
      </div>
      <div className="container">
        <div className="d-flex flex-fill">
          <div className="d-flex flex-fill py-4 col-10">
            <div className="col-12 rounded bg-info text-white fs-4 p-4">
              At Pixominia, we are passionate about fostering a community of
              inspired creators. Your success is our success, and we are
              <br />
              dedicated to continually expanding our offerings and enhancing
              your creative experience.
              <div className="my-3 fs-5 fw-bold">
                Pixominia -
                <span className="fw-semibold"> Where Your Ideas Matter.</span>
              </div>
            </div>
            <div
              className="bg-info d-none d-md-block"
              style={{
                width: "10px",
                flex: "1 0 5%",
                transform: "translateX(-100%)",
              }}
            ></div>
          </div>
          {/* <div
            className="col-md-5 col-lg-3 d-none d-md-block"
            style={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage:
                "url('https://profilemagazine.com/wp-content/uploads/2020/04/Ajmere-Dale-Square-thumbnail.jpg')",
            }}
          ></div> */}
        </div>
      </div>
    </div>
  );
}
