import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { submitRequest as submitRequestApi } from "../../api/requestapi";
import * as REQUEST_API from "../../api/requestapi";

let initialState = {
  requestData: null,
  status: "idle",
  error: null,
  requestDataLoader: false,
};
// Async thunk to handle the request submission
export const submitRequest = createAsyncThunk(
  "request/submitRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const data = await REQUEST_API.submitRequest(requestData);
      if (typeof data === "object") {
        return data;
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    resetRequestState: (state) => {
      state.requestData = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitRequest.pending, (state) => {
        state.status = "loading";
        state.requestDataLoader = true;
      })
      .addCase(submitRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.requestDataLoader = false;
        state.requestData = action.payload;
      })
      .addCase(submitRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default requestSlice;
