import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../store";
import Logo from "../images/Logo.svg";
import New from "../images/new_icon_blink.gif";

export default function Header() {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [profilepic, setprofilepic] = useState(null);
  const emojiStyle = {
    fontSize: "4rem", // Adjust the font size as needed
    animation: "scaleInOut 1s infinite alternate", // Adjust the animation duration as needed
  };
  // const { isLoggedIn } = useSelector((state) => state.user);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    var user = window.localStorage.getItem("user");
    var userdata =
      user === null ? null : JSON.parse(window.localStorage.getItem("user"));

    setprofilepic(`http://bootdey.com/img/Content/avatar/avatar7.png`);
    var usertoken = window.localStorage.getItem("accessToken");
    if (usertoken !== null && usertoken !== "") {
      setisLoggedIn(true);
    }
  }, []);
  const handleLogoClick = (e) => {
    if (window.location.pathname === "/") {
      e.preventDefault(); // Prevent navigation
      window.location.reload(); // Reload the page
    }
  };

  return (
    <header className="py-0 mt-3 mt-lg-0 py-lg-4">
      <div className="container col-12 d-flex justify-content-between align-items-center">
        <Link to="/" onClick={handleLogoClick}>
          <img
            src={Logo}
            style={{ height: "44px", width: "auto" }}
            alt="Pixominia Logo"
          />
        </Link>
        <div className="d-flex">
          {/* <a
            role="button"
            href="/admin/seller"
            className="text-reset text-decoration-none mx-3 py-2 px-3 fw-semibold d-none d-sm-block"
          >
            Sell Your Content
          </a> */}
          {!isLoggedIn ? (
            <>
              <Link
                role="button"
                to="/signup"
                className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
              >
                Sign up
              </Link>
              <Link
                role="button"
                to="/login"
                className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
              >
                Login
              </Link>
            </>
          ) : (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <img
                    className="d-none d-lg-block"
                    src={New}
                    style={{
                      height: "13px",
                      position: "relative",
                      zIndex: 1,
                      top: -5,
                      left: 20,
                      cursor: "none",
                    }}
                    alt="New Gif"
                  />
                </div>
                <Link
                  to={`/request-your-need`}
                  className="text-reset text-decoration-none mx-3 py-2 px-3 fs-5 fw-semibold d-none d-lg-block"
                >
                  Request Your Need
                </Link>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <img
                    className="d-block d-lg-none"
                    src={New}
                    style={{
                      height: "13px",
                      position: "relative",
                      zIndex: 1,
                      top: -15,
                      left: 15,
                      cursor: "none",
                    }}
                    alt="New Gif"
                  />
                </div>
                <a
                  role="button"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdnze-mmrLvP509xvP882ayWQD7fB4rokAqFMqVxL36U6WvZA/viewform?usp=sf_link"
                  target="_blank"
                  rel="noopener"
                  className="text-reset text-decoration-none me-3 py-2 fw-bold d-block d-lg-none"
                >
                  Request Your Need
                </a>
              </div>
              <div className="dropdown">
                <div
                  className="btn btn-secondary rounded-circle"
                  style={{
                    height: "44px",
                    width: "44px",
                    backgroundSize: "cover",
                    backgroundImage:
                      profilepic != null
                        ? `url(${profilepic})`
                        : "url('http://bootdey.com/img/Content/avatar/avatar1.png')",
                  }}
                  role="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link className="dropdown-item" to="/user-profile">
                      Edit Profile
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        dispatch(userActions.setLoginResponse({ empty: true }));
                        window.localStorage.clear();
                        window.location.reload(true);
                      }}
                    >
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
