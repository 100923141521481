import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import girlMobile from "../images/girl-mobile.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { registerUser } from "../store/slices/userSlice";
import { userActions } from "../store";
import Spinner from "../components/Spinner";
import { isObjectEmpty } from "../utils";
export default function Login() {
  const dispatch = useDispatch();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const navigate = useNavigate();
  const { response, showRegisterLoader } = useSelector((state) => state.user);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    mobileno: "",
    first_name: "",
  });

  useEffect(() => {
    let valid = isObjectEmpty({ ...response });
    if (valid) {
      toast[response.type](response.message);
      if (response.type == "success") {
        navigate("/login");
        dispatch(userActions.setResponse({ empty: true }));
      }
    }
  }, [response]);

  const handleUpdateData = (e) => {
    try {
      let key = e.currentTarget.id;
      let temp = { ...userData };
      temp[key] = e.currentTarget.value;
      setUserData(temp);
    } catch (err) {
      console.log("handleUpadteData ::: ", err);
    }
  };
  const handleSignUp = (e) => {
    try {
      e.preventDefault();
      let checkData = { ...userData };
      let allkeys = Object.keys(checkData);
      for (var k in Object.keys(checkData)) {
        let keyName = allkeys[k];
        let item = checkData[allkeys[k]];
        if (item == "") {
          toast.error(`${keyName} should not be empty`);
          console.log();
          return;
        }
      }
      dispatch(registerUser(checkData));
    } catch (err) {
      console.log("error in handleSignUp::::", err);
    }
  };
  return (
    <div className="overflow-hidden">
      <div className="container pb-4 " style={{ maxWidth: "516px" }}>
        <h2 className="fw-bold ps-3 mb-4"> SIGN UP </h2>
        <div className="d-flex flex-nowwrap bg-white">
          <form
            className="rounded shadow col-12 p-4"
            onSubmit={(e) => {
              handleSignUp(e);
            }}
          >
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-dark fs-5">
                Email
              </label>
              <input
                type="email"
                className="form-control py-3 fs-5"
                id="email"
                onChange={(e) => {
                  handleUpdateData(e);
                }}
                placeholder="your@gmail.com"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label text-dark fs-5">
                Full name
              </label>
              <input
                type="text"
                className="form-control py-3 fs-5"
                id="first_name"
                onChange={(e) => {
                  handleUpdateData(e);
                }}
                placeholder="Name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label text-dark fs-5">
                Phone
              </label>
              <input
                type="number"
                onChange={(e) => {
                  handleUpdateData(e);
                }}
                className="form-control py-3 fs-5"
                id="mobileno"
                placeholder="+91 __ __-__-__"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label text-dark fs-5">
                Password
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="password"
                  onChange={(e) => {
                    handleUpdateData(e);
                  }}
                  className="form-control py-3 col-12  fs-5"
                  id="password"
                />
                <i
                  role="button"
                  className="fa fa-eye"
                  toggletargetId="password"
                  toggleShow="true"
                  onClick={togglePassword}
                  style={{
                    position: "relative",
                    right: "40px",
                    color: "#5c5c5c",
                  }}
                ></i>
              </div>
              <div id="password-help" className="form-text">
                {" "}
                8 or more characters, at least 1 letter and 1 number{" "}
              </div>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberMe"
                onChange={(e) => {
                  setTermsAccepted(e.currentTarget.checked);
                }}
              />
              <label className="form-check-label" htmlFor="rememberMe">
                {" "}
                I agree with{" "}
                <Link
                  className="text-color text-decoration-none"
                  to="/Terms&Conditions"
                >
                  {" "}
                  terms&conditions{" "}
                </Link>{" "}
              </label>
            </div>

            <div className="d-flex flex-column align-items-center py-3">
              <div
                role="button"
                className="rounded bg-color text-white py-2 px-4 fs-5 fw-semibold mb-3"
              >
                {showRegisterLoader ? (
                  <Spinner />
                ) : (
                  <button
                    type="submit"
                    className="none"
                    disabled={!termsAccepted}
                  >
                    Sign up
                  </button>
                )}
              </div>
              <span className="fw-semibold">Already have an account?</span>
              <Link
                className="text-decoration-none fs-5 fw-semibold text-color"
                to="/login"
              >
                {" "}
                LOG IN{" "}
              </Link>
            </div>
          </form>
          <div className="d-flex align-items-end">
            <img
              className="d-none d-md-block"
              src={girlMobile}
              alt="a vector girl character with a mobile in her hand"
              style={{ position: "relative", top: "-10px", left: "-20px" }}
            ></img>
          </div>{" "}
        </div>
      </div>
    </div>
  );

  function togglePassword(e) {
    if (e.target.getAttribute("toggleShow") == "true") {
      e.target.setAttribute("toggleShow", "false");
      document.getElementById(e.target.getAttribute("toggletargetId")).type =
        "text";
    } else {
      e.target.setAttribute("toggleShow", "true");
      document.getElementById(e.target.getAttribute("toggletargetId")).type =
        "password";
    }
  }
}
