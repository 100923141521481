import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { adminloginApi } from '../../api/userapi.js';
let initialState = {
    isadmin: false

}
export const adminlogin = createAsyncThunk(
    'login',
    async (data) => {
        try {
            let res = await adminloginApi(data)
            if (res.status === 200) {
                adminSlice.setResponse()
                return {
                    type: "success",
                    isadmin: true,
                    "message": "Admin logged in"

                }
            }
            else if (res.status === 409) {
                return {
                    type: "info",

                    message: res.data.message
                }
            }
            else if (res.status === 500) {
                return {
                    "type": "error",
                    isadmin: false
                    ,
                    "message": res.data.message
                }
            }
        }
        catch (err) {
            console.log("error in login user", err)
            return {
                type: "error",
                message: err.message
            }
        }
    }
)



const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {

        setResponse: (state, action) => {

            state.isadmin = true




        },
        extraReducers: (builder) => {
            builder.addCase(adminlogin.pending, (state, action) => {
                state.showLoggedInLoader = true
            })
            builder.addCase(adminlogin.fulfilled, (state, action) => {
                state.showLoggedInLoader = false
                if (action.type = "success") {

                    state.isadmin = true
                }
                state.loginResponse = {
                    type: action.payload.type,
                    message: action.payload.message
                }

            })

        }
    },



})

export default adminSlice;