import apiClient from "./index.js";
import { toast } from "react-toastify";

export async function registerUserApi(data) {
  try {
    let res = await apiClient.post("/user/signup", data);
    return res;
  } catch (err) {
    console.log("error in register User ::: ", err);
    return err.response;
  }
}

export async function loginUserApi(data) {
  try {
    let res = await apiClient.post("/user/login", data);
    return res;
  } catch (err) {
    toast.error(err.response.data.message);
    console.log("error in loginUserApi::::", err);
    throw err;
  }
}

export async function otpUserApi(data) {
  try {
    let res = await apiClient.post("/user/verify", data);
    if (res.status === 200) {
      toast.success("OTP sent successfully");
    }
    return res;
  } catch (err) {
    toast.error(err.response.data.message);
    console.log("error in otpUserApi::::", err);
    // return err.response
  }
}

export async function forgotUserApi(data) {
  try {
    let res = await apiClient.post("/user/forgotPassword", data);
    if (res.status === 200) {
      toast.success("Reset link sent to your mail");
    }
    return res;
  } catch (err) {
    toast.error(err.response.data.message);
    console.log("error in otpUserApi::::", err);
    // return err.response
  }
}

export async function changeUserPasswordApi(data) {
  try {
    let res = await apiClient.post("/user/resetPassword", data);
    if (res.status === 200) {
      toast.success("Password Updated Successfully");
    }
    return res;
  } catch (err) {
    toast.error(err.response.data.message);
    console.log("error in changingUserPassword::::", err);
    // return err.response
  }
}

export async function adminloginApi(data) {
  try {
    let res = await apiClient.post("/login", data);
    console.log({ res });
    return res;
  } catch (err) {
    console.log("error in loginUserApi::::", err);
    return err.response;
  }
}
