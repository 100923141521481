import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import adminSlice from "./slices/adminSlice";
import videoSlice from "./slices/videoSlice";
import requestSubmit from "./slices/requestSlice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    video: videoSlice.reducer,
    admin: adminSlice.reducer,
    request: requestSubmit.reducer, // Ensure this is correct
  },
});

export const userActions = userSlice.actions;
export const videoActions = videoSlice.actions;
export const adminActions = adminSlice.actions;
export const requestActions = requestSubmit.actions;
export default store;
