import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { changeUserPassword } from "../store/slices/userSlice";
import FontAwesome from "react-fontawesome";
import Header from "../components/Header";

export default function ForgotPassword() {
  const { status } = useSelector((state) => state.user);
  const [data, setData] = useState({
    password: null,
    token: null,
    confirmPassword: null,
  });
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const handleState = (e) => {
    let temp = { ...data };
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    temp[key] = value;
    setData(temp);
  };
  const handleSendLink = (e) => {
    try {
      e.preventDefault();
      let temp = { ...data };
      const { password, confirmPassword } = temp;
      if (password.length < 8) {
        toast.warn("Password should be atleast 8 characters long");
        return;
      }
      if (!password) {
        toast.warn("Password is missing");
        return;
      }
      if (!confirmPassword) {
        toast.warn("Please Confirm Your Password");
        return;
      }
      if (password !== confirmPassword) {
        toast["warn"]("Password don't match");
      }
      dispatch(changeUserPassword(temp));
    } catch (err) {
      toast["error"]("Error in changing password");
      console.log("handleOtp ::::::::::: ", err);
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get("token");
    setData({ ...data, token: resetToken });
  }, []);
  
  useEffect(() => {
    if (status === "success") {
      navigate("/login");
    }
  }, [status]);
  return (
    <div className=" container overflow-hidden ">
      <Header> </Header>
      <div
        className="container pb-4"
        style={{ maxWidth: "516px", marginTop: "220px" }}
      >
        <h2 className="fw-bold ps-3 mb-4"> Enter New Password </h2>
        <div className="d-flex flex-nowrap">
          <form
            className="rounded shadow col-12 p-4"
            onSubmit={(e) => {
              handleSendLink(e);
            }}
          >
            <div className="mb-3">
              <label htmlFor="password" className="form-label text-dark fs-5">
                New Password
              </label>
              <input
                type="password"
                onChange={(e) => {
                  handleState(e);
                }}
                className="form-control py-3 fs-5"
                id="password"
                placeholder="Enter New Password"
              />
            </div>
            {data.password && data.password.length < 14 && (
              <div className="mb-3">
                <label
                  htmlFor="confirmPassword"
                  className="form-label text-dark fs-5"
                >
                  Confirm Password
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    handleState(e);
                  }}
                  className="form-control py-3 fs-5"
                  id="confirmPassword"
                  placeholder="confirm password"
                />
              </div>
            )}
            <div id="password-help" className="form-text">
              {" "}
              Password must be 8 or more characters
              <br /> Include at least 1 letter, 1 number, and 1 special
              character{" "}
            </div>
            <div className="d-flex flex-column align-items-center py-3  mt-3">
              <div
                role="button"
                className="rounded bg-info text-white py-2 px-4 fs-5 fw-semibold"
              >
                {" "}
                {status !== "success" ? (
                  <button
                    onClick={(e) => {
                      handleSendLink(e);
                    }}
                    className="none"
                  >
                    {status === "error" ? (
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Change Password"
                    )}
                  </button>
                ) : (
                  <FontAwesome
                    role="button"
                    className="fa-solid fa-check pt-2 fa-lg"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
