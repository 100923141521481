import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAxios } from "../../utils/axiosprovider";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { getAllTransitions, searchVideos } from "../../store/slices/videoSlice";

export default function CharacterManagemant() {
  const inputRef = useRef(null);
  const { deletebyId, updatebyId } = useAxios();
  const dispatch = useDispatch();
  const [saveLoading, setLoading] = useState("");
  // const [tags, setTags] = useState([]);
  const [videoData, setVideoData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const { allTransitions, totalPages } = useSelector((state) => state.video);
  const [currentPage, setCurrentPage] = useState(1);

  const renderPagination = () => {
    const paginationItems = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <button
            style={{ backgroundColor: "#227B94" }}
            key={i}
            className={`btn mx-1 text-light${
              i === currentPage
                ? "active bg-danger text-light  border-danger"
                : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      const startPage = Math.max(
        1,
        Math.min(
          currentPage - Math.floor(maxVisiblePages / 2),
          totalPages - maxVisiblePages + 1
        )
      );
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (startPage > 1) {
        paginationItems.push(
          <button
            key={1}
            style={{
              backgroundColor: `${1 === currentPage ? "#C96868 " : "#227B94"}`,
            }}
            className={`btn mx-1 text-light${
              1 === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
        if (startPage > 2) {
          paginationItems.push(
            <span key="ellipsis-start" className="mx-2">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <button
            key={i}
            style={{
              backgroundColor: `${i === currentPage ? "#C96868 " : "#227B94"}`,
            }}
            className={`btn mx-2 text-light${
              i === currentPage ? "active text-light  " : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push(
            <span key="ellipsis-end" className="mx-1">
              ...
            </span>
          );
        }
        // paginationItems.push(
        //   <button
        //     key={totalPages}
        //     className={`btn btn-secondary mx-2 bg-info border-info ${
        //       totalPages === currentPage ? "active" : ""
        //     }`}
        //     onClick={() => handlePageChange(totalPages)}
        //   >
        //     {totalPages}
        //   </button>
        // );
      }
    }
    return paginationItems;
  };
  useEffect(() => {
    dispatch(getAllTransitions({ page: currentPage }));
  }, [dispatch, currentPage, deletebyId]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
    setTimeout(function () {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  };
  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      await updatebyId(videoData.id, {
        name: videoData.name,
        title: videoData.title,
        paragraph: videoData.paragraph,
        description: videoData.description,
        tags: videoData.tags,
        "color.location": videoData.mp4Link,
        metaTags: videoData.metaTags,
        keywords: videoData.keywords,
      });
      dispatch(getAllTransitions({ page: currentPage }));
    } catch (error) {
      console.error("Error fulfilling request", error);
      alert("Failed to Saving Video. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const getSerialNumber = (index) => {
    return (currentPage - 1) * 12 + index + 1;
  };
  const handleState = (e) => {
    const { id, value } = e.currentTarget;
    setVideoData((prev) => ({ ...prev, [id]: value }));
  };
  const handleEditClick = (e) => {
    // setTags(e.tags.map((element) => ({ id: element, text: element })));
    setVideoData({
      id: e._id,
      title: e.title || "",
      name: e.name,
      mp4Link: e.color.location,
      paragraph: e.paragraph || "",
      metaTags: e.metaTags,
      description: e.description || "",
      keywords: e.keywords,
      tags: e.tags,
    });
  };
  const handleMetaTagChange = (index, field, value) => {
    const newMetaTags = [...videoData.metaTags];
    newMetaTags[index][field] = value;
    setVideoData((prev) => ({ ...prev, metaTags: newMetaTags }));
  };
  const handleMetaTagAdd = () => {
    setVideoData((prev) => ({
      ...prev,
      metaTags: [...prev.metaTags, { name: "", content: "" }],
    }));
  };
  const handleMetaTagRemove = (index) => {
    setVideoData((prev) => ({
      ...prev,
      metaTags: prev.metaTags.filter((_, i) => i !== index),
    }));
  };
  const handleKeywordInput = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault(); // Prevent form submission or default behavior
      let inputValue = e.target.value.trim();

      // Split the input by commas, trim spaces and filter empty strings
      let keywordsToAdd = inputValue
        .split(",")
        .map((keyword) => keyword.trim())
        .filter(
          (keyword) =>
            keyword.length > 0 && !videoData.keywords.includes(keyword)
        ); // Avoid empty or duplicate keywords

      if (keywordsToAdd.length > 0) {
        setVideoData((prevState) => ({
          ...prevState,
          keywords: [...prevState.keywords, ...keywordsToAdd], // Add the new keywords
        }));
      }

      e.target.value = ""; // Clear the input box after adding the keywords
    }
  };
  const handleKeywordRemove = (index) => {
    setVideoData((prevState) => ({
      ...prevState,
      keywords: prevState.keywords.filter((_, i) => i !== index), // Remove the tag by index
    }));
  };
  const handleTagInput = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      let inputValue = e.target.value.trim();

      let tagsToAdd = inputValue
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag.length > 0 && !videoData.tags.includes(tag)); // Avoid empty or duplicate keywords

      if (tagsToAdd.length > 0) {
        setVideoData((prevState) => ({
          ...prevState,
          tags: [...prevState.tags, ...tagsToAdd],
        }));
      }

      e.target.value = "";
    }
  };
  const handleTagRemove = (index) => {
    setVideoData((prevState) => ({
      ...prevState,
      tags: prevState.tags.filter((_, i) => i !== index), // Remove the tag by index
    }));
  };

  const debouncedSearch = useCallback(
    debounce(({ query }) => {
      if (query) {
        dispatch(searchVideos({ query }))
          .unwrap()
          .then((result) => {
            console.log("Videos fetched: ", result);
          })
          .catch((error) => {
            console.error("Error fetching videos: ", error);
          });
      }
    }, 250),
    [dispatch]
  );

  const handleSearch = (searchedKey) => {
    const inputText =
      searchedKey === null
        ? inputRef.current.value.toLowerCase()
        : searchedKey.toLowerCase();
    setSearchKey(inputText);
    // setVideoMsg("Loading...");
    debouncedSearch({ query: inputText });
    window.scrollTo({ top: 450, left: 0, behavior: "smooth" });
  };
  return (
    <div className="content-wrapper px-5 text-secondary mt-5">
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#C96868" }}
            >
              <h5 className="modal-title text-light" id="exampleModalLabel">
                Edit Characters
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#FADFA1" }}>
              <form>
                <div className="mb-2">
                  <label
                    htmlFor="name"
                    className="form-label text-dark fw-semibold"
                  >
                    Character Name
                  </label>
                  <input
                    type="text"
                    value={videoData.name}
                    className="form-control text-dark"
                    id="name"
                    onChange={handleState}
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="tags"
                    className="form-label text-dark fw-semibold"
                  >
                    Tags
                  </label>
                  <div className="d-flex flex-wrap mb-2">
                    {(videoData.tags || []).map((tags, index) => (
                      <div
                        key={index}
                        className="keyword-tag d-flex align-items-center me-2 mb-2 rounded"
                        style={{ backgroundColor: "#227B94" }}
                      >
                        <span className="badge p-2 me-1">{tags}</span>
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          aria-label="Remove"
                          onClick={() => handleTagRemove(index)}
                        ></button>
                      </div>
                    ))}
                    <input
                      type="text"
                      className="form-control"
                      onKeyDown={handleTagInput}
                      placeholder="Add a Tag and press Enter"
                    />
                  </div>
                </div>
                <div>
                  {(videoData.metaTags || []).map((metaTag, index) => (
                    <div key={index} className="d-flex mb-2">
                      <input
                        type="text"
                        value={metaTag.name}
                        onChange={(e) =>
                          handleMetaTagChange(index, "name", e.target.value)
                        }
                        className="form-control me-2"
                        placeholder="Tag Name"
                      />
                      <input
                        type="text"
                        value={metaTag.content}
                        onChange={(e) =>
                          handleMetaTagChange(index, "content", e.target.value)
                        }
                        className="form-control me-2"
                        placeholder="Tag Content"
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleMetaTagRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn text-light"
                    onClick={handleMetaTagAdd}
                    style={{ backgroundColor: "#227B94" }}
                  >
                    Add Meta Tag
                  </button>
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="paragraph"
                    className="form-label text-dark fw-semibold"
                  >
                    Paragraph
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleState}
                    value={videoData.paragraph}
                    id="paragraph"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="title"
                    className="form-label text-dark fw-semibold"
                  >
                    Page Title
                  </label>
                  <input
                    type="text"
                    onChange={handleState}
                    value={videoData.title}
                    className="form-control"
                    id="title"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="description"
                    className="form-label text-dark fw-semibold"
                  >
                    Page Description
                  </label>
                  <input
                    type="text"
                    onChange={handleState}
                    value={videoData.description}
                    className="form-control"
                    id="description"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="keywords"
                    className="form-label text-dark fw-semibold"
                  >
                    Page Keyword
                  </label>
                  <div className="d-flex flex-wrap mb-2">
                    {(videoData.keywords || []).map((keyword, index) => (
                      <div
                        key={index}
                        className="keyword-tag d-flex align-items-center me-2 mb-2 rounded"
                        style={{ backgroundColor: "#227B94" }}
                      >
                        <span className="badge p-2 me-1">{keyword}</span>
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          aria-label="Remove"
                          onClick={() => handleKeywordRemove(index)}
                        ></button>
                      </div>
                    ))}
                  </div>

                  <input
                    type="text"
                    className="form-control"
                    onKeyDown={handleKeywordInput}
                    placeholder="Add a keyword and press Enter"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="mp4Link"
                    className="form-label text-dark fw-semibold"
                  >
                    MP4 Link
                  </label>
                  <input
                    type="text"
                    onChange={handleState}
                    value={videoData.mp4Link}
                    className="form-control"
                    id="mp4Link"
                  />
                </div>
              </form>
            </div>
            <div
              className="modal-footer"
              style={{ backgroundColor: "#FADFA1" }}
            >
              <button
                type="button"
                className="btn text-light border-0"
                style={{ backgroundColor: "#227B94" }}
                onClick={handleSaveChanges}
                disabled={saveLoading}
                data-bs-dismiss={!saveLoading ? "modal" : ""}
              >
                {saveLoading ? "Saving..." : "Save changes"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h4 className="fw-bold ">Character Management</h4>
            <input
              type="text"
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e.currentTarget.value);
                } else {
                }
              }}
              className="form-control w-25"
              placeholder="Search characters..."
            />
          </div>
          <div className="row">
            {allTransitions.length > 0 &&
              allTransitions.map((e, i) => (
                <div key={e._id} className="col-md-3 mb-3">
                  <div
                    className="card h-100"
                    key={e._id}
                    style={{ backgroundColor: "#FADFA1" }}
                  >
                    <div
                      className="card-header text-light"
                      style={{ backgroundColor: "#C96868" }}
                    >
                      <h5 className="mb-0">
                        {getSerialNumber(i)}.{" "}
                        {e.name > 25 ? e.name : e.name.slice(0, 25)}
                      </h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <b> Page Title:</b> {e.title}
                      </p>
                      <p>
                        <b> Created Date:</b>{" "}
                        {new Date(e.createdAt).toLocaleString()}
                      </p>
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-danger text-light"
                        onClick={() => {
                          deletebyId(e._id);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn text-light"
                        style={{ backgroundColor: "#227B94" }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => handleEditClick(e)}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center pt-3">
          <button
            className="btn mx-3 text-light"
            style={{ backgroundColor: "#227B94" }}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {renderPagination()}
          <button
            className="btn mx-3 text-light"
            style={{ backgroundColor: "#227B94" }}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
