import React, { useCallback, useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransitions } from "../store/slices/videoSlice";
import { BACKEND_URL } from "../utils";
import "./Home.css";
import { userActions } from "../store";
import Logo from "../images/Logo.svg";
import New from "../images/new_icon_blink.gif";
import debounce from "lodash.debounce";
import FontAwesome from "react-fontawesome";

export default function TransitionPage() {
  const { allTransitions, totalPages } = useSelector((state) => state.video);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedColor, setSelectedColor] = useState("color");
  const [selectedObjectColor, setSelectedObjectColor] = useState("");
  const [colorSlctd, setColorSlctd] = useState(false);
  const [videoMsg, setVideoMsg] = useState(
    <div className="d-flex justify-content-center">
      <div className="text-color spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilepic, setProfilePic] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [searchedVideos, setSearchedVideos] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const [downloading, setDownloading] = useState(false);
  const isLoggedIn = window.localStorage.getItem("accessToken") ? true : false;
  const [currentPage, setCurrentPage] = useState(1);
  const renderPagination = () => {
    const paginationItems = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`mx-1 bg-color border-color text-light px-3 rounded fs-5 ${
              i === currentPage ? "active bg-danger border-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      const startPage = Math.max(
        1,
        Math.min(
          currentPage - Math.floor(maxVisiblePages / 2),
          totalPages - maxVisiblePages + 1
        )
      );
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (startPage > 1) {
        paginationItems.push(
          <button
            key={1}
            className={`mx-1 bg-color border-color text-light px-3 rounded fs-5 ${
              1 === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
        if (startPage > 2) {
          paginationItems.push(
            <span key="ellipsis-start" className="mx-2">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`mx-1 bg-color border-color text-light px-3 rounded fs-5${
              i === currentPage ? "active border-danger bg-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push(
            <span key="ellipsis-end" className="mx-1">
              ...
            </span>
          );
        }
        // paginationItems.push(
        //   <button
        //     key={totalPages}
        //     className={`btn btn-secondary mx-2 bg-info border-info ${
        //       totalPages === currentPage ? "active" : ""
        //     }`}
        //     onClick={() => handlePageChange(totalPages)}
        //   >
        //     {totalPages}
        //   </button>
        // );
      }
    }
    return paginationItems;
  };
  useEffect(() => {
    dispatch(getAllTransitions({ page: currentPage }));
  }, [dispatch, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (inputText) => {
      try {
        const response = await fetch(
          `http://localhost:9000/api/v1/searchVideos?query=${inputText}`
        );
        const data = await response.json();
        if (data.status === "success") {
          setSearchedVideos(data.data);
          setVideoMsg(data.data.length > 0 ? "" : "No Videos");
        } else {
          setVideoMsg("Error fetching videos");
        }
      } catch (error) {
        setVideoMsg("Error fetching videos");
      }
    }, 250),
    []
  );

  const handleSearch = (searchedKey) => {
    const inputText =
      searchedKey?.toLowerCase() || inputRef.current.value.toLowerCase();
    setSearchKey(inputText);
    setVideoMsg("Loading...");
    debouncedSearch(inputText);
    window.scrollTo({ top: 450, left: 0, behavior: "smooth" });
  };

  const handleRequestClick = () => {
    navigate("/request-your-need");
    setTimeout(function () {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  };
  function hoverInVideo(e) {
    const videoElement = e.currentTarget.getElementsByTagName("video")[0];
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play().catch((error) => {});
      }
    }
  }
  function hoverOutVideo(e) {
    const videoElement = e.currentTarget.getElementsByTagName("video")[0];
    if (videoElement && !videoElement.paused) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }
  function togglePlay() {
    if (document.getElementById("mainVideo").paused) {
      document.getElementById("mainVideo").play();
      document.getElementById("playPauseBtn").classList.add("fa-pause");
      document.getElementById("playPauseBtn").classList.remove("fa-play");
    } else {
      document.getElementById("mainVideo").pause();
      document.getElementById("playPauseBtn").classList.add("fa-play");
      document.getElementById("playPauseBtn").classList.remove("fa-pause");
    }
  }
  function videoModelClose() {
    setSelectedObject(null);
    setSelectedObjectColor("");
    document.getElementById("video-model").classList.remove("d-flex");
    document.getElementById("video-model").classList.add("d-none");
    // document.getElementById('video-src').src()
  }
  function videoModelOpen(e) {
    let selection = JSON.parse(e.currentTarget.getAttribute("data-item"));
    setSelectedObject(selection);
    if (selectedObjectColor === "") setSelectedObjectColor(selectedColor);
    document.getElementById("video-model").classList.add("d-flex");
    document.getElementById("video-model").classList.remove("d-none");
    document.getElementById("mainVideo").load();
  }
  function handleDownload(e) {
    e.preventDefault();
    const selectedFile =
      "imagegif" in selectedObject[selectedObjectColor]
        ? selectedObject[selectedObjectColor].imagegif
        : null;

    if (selectedFile) {
      if (!downloading) {
        setDownloading(true);
        e.target.innerHTML = "0% Downloading...";
        e.target.setAttribute("disabled", "disabled");

        let link = e.currentTarget.getAttribute("href");

        fetch(link)
          .then((response) => {
            const contentLength = response.headers.get("content-length");
            if (!contentLength) {
              throw new Error("Content-Length response header is missing.");
            }

            const totalBytes = parseInt(contentLength, 10);
            let receivedBytes = 0;

            const reader = response.body.getReader();
            const stream = new ReadableStream({
              start(controller) {
                function push() {
                  reader.read().then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }

                    receivedBytes += value.length;
                    const percentage = Math.floor(
                      (receivedBytes / totalBytes) * 100
                    );
                    updateProgress(percentage, e.target);
                    // e.target.innerHTML = `${percentage}% Downloading...`;
                    e.target.innerHTML = `Downloading...`;

                    controller.enqueue(value);
                    push();
                  });
                }
                push();
              },
            });

            return new Response(stream);
          })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.setAttribute("download", selectedFile.originalname);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            e.target.innerHTML = "Download";
            e.target.removeAttribute("disabled");
            setDownloading(false);
          })
          .catch((err) => {
            console.error("Download failed", err);
            e.target.innerHTML = "Download Failed";
            setDownloading(false);
          });
      }
    }
  }
  function updateProgress(progress, button) {
    const progressBar = document.getElementById("download-progress-bar");
    if (progressBar) {
      progressBar.style.width = `${progress}%`;
      progressBar.innerHTML = `${Math.round(progress)}%`;
    }

    // Update the button text with the progress
    button.innerHTML = `Downloading ${Math.round(progress)}%...`;
  }
  useEffect(() => {
    setProfilePic("http://bootdey.com/img/Content/avatar/avatar7.png");
    const handleKeyPress = (event) => {
      if (
        event.code === "Space" &&
        !["INPUT", "TEXTAREA"].includes(event.target.tagName)
      ) {
        event.preventDefault();
        togglePlay();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, []);
  useEffect(() => {
    const videoElement = document.getElementById("mainVideo");
    if (videoElement) {
      const handleTimeUpdate = () => setCurrentTime(videoElement.currentTime);
      const handleMetadataLoaded = () =>
        setVideoDuration(videoElement.duration);
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      };
    }
  }, []);
  return (
    <div className="bg-white">
      <div className="container-fluid position-fixed top-0 z-2">
        <div
          className="row py-3 align-items-center"
          style={{ backgroundColor: "#80e8d4" }}
        >
          <div className="col-4 d-flex justify-content-center">
            <Link to="/">
              <img
                src={Logo}
                style={{ height: "44px", filter: "brightness(0) invert(1)" }}
                alt="Pixominia Logo"
              />
            </Link>
          </div>
          <div className="col-8 col-lg-4">
            <div className="d-flex justify-content-evenly align-items-center col-12 px-2 py-3 rounded border bg-white">
              <input
                className="flex-fill border-0 bg-transparent fs-5 ps-3"
                ref={inputRef}
                placeholder="Search..."
                type="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(null, e.currentTarget.value, null);
                  } else {
                  }
                }}
              />
              <label> </label>
              <i
                className="fa fa-search fa-lg"
                onClick={() => handleSearch(null, null, null)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          <div className="col-4 d-none d-lg-flex justify-content-center">
            {!isLoggedIn ? (
              <>
                <Link
                  role="button"
                  to="/signup"
                  className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
                >
                  Sign up
                </Link>
                <Link
                  role="button"
                  to="/login"
                  className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
                >
                  Login
                </Link>
              </>
            ) : (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="d-none d-lg-block"
                    src={New}
                    style={{
                      height: "13px",
                      position: "relative",
                      zIndex: 1,
                      top: -5,
                      left: 15,
                      cursor: "none",
                    }}
                    alt="New Gif"
                  />
                </div>
                <Link
                  to={`/request-your-need`}
                  className="text-reset text-decoration-none me-3 py-2 px-3 fs-5 fw-semibold d-none d-lg-block"
                >
                  Request Your Need
                </Link>
                <div className="dropdown">
                  <div
                    className="btn btn-secondary rounded-circle"
                    style={{
                      height: "44px",
                      width: "44px",
                      backgroundSize: "cover",
                      backgroundImage:
                        profilepic != null
                          ? `url(${profilepic})`
                          : "url('http://bootdey.com/img/Content/avatar/avatar1.png')",
                    }}
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to="/user-profile">
                        Edit Profile
                      </Link>
                    </li>
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          dispatch(
                            userActions.setLoginResponse({ empty: true })
                          );
                          window.localStorage.clear();
                          window.location.reload(true);
                        }}
                      >
                        Log Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container mt-5 z-1">
        <div className="row pt-5">
          <div className="col-12">
            <h1 className="text-center my-5 font3">
              {" "}
              DOWNLOAD AND SAVE YOUR TIME{" "}
            </h1>
            <div className="col-lg-12 d-flex flex-wrap justify-contant-evenly">
              {searchKey !== "" || colorSlctd ? (
                <>
                  {searchedVideos.length > 0 &&
                    searchedVideos.map((item, index) => {
                      if (selectedColor in item) {
                        return (
                          <div
                            key={item._id}
                            className="col-12 col-sm-6 col-md-4 col-lg-3 p-3"
                            data-item={JSON.stringify(item)}
                            onClick={(e) => {
                              videoModelOpen(e);
                            }}
                            onMouseOver={hoverInVideo}
                            onMouseOut={hoverOutVideo}
                          >
                            <div
                              className="rounded col-12 shadow"
                              key={item._id}
                            >
                              <div>
                                <video
                                  className="col-12 multiVideo"
                                  loop
                                  muted
                                  key={item[selectedColor].location}
                                >
                                  <source
                                    src={
                                      "location" in item[selectedColor]
                                        ? item[
                                            selectedColor
                                          ].location.startsWith("https://s3.")
                                          ? item[selectedColor].location
                                          : item[selectedColor].location
                                              .replace(
                                                "/assets.pixominia.com.",
                                                "/"
                                              )
                                              .replace(
                                                ".com/",
                                                ".com/assets.pixominia.com/"
                                              )
                                        : `${process.env.REACT_APP_BACKEND_URL}/uploads/${item[selectedColor].filename}`
                                    }
                                  />
                                </video>
                              </div>
                              <div className="d-flex col-12 justify-content-between col-12 py-2 px-2 border-top fs-5">
                                <span className="fw-semibold">
                                  {" "}
                                  {item.name.length > 15
                                    ? item.name.slice(0, 15) + ".."
                                    : item.name}
                                </span>
                                <FontAwesome
                                  name="download"
                                  role="button"
                                  className="fas fa-download"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {searchedVideos.length < 1 && (
                    <div>
                      <div
                        onClick={handleRequestClick}
                        className="btn btn-info text-white btn-lg active text-dark"
                        role="button"
                        aria-pressed="true"
                      >
                        Request Your Need
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {allTransitions.length > 0 &&
                    allTransitions.map((item, index) => {
                      if (selectedColor in item) {
                        return (
                          <div
                            key={item._id}
                            className="col-12 col-sm-6 col-md-4 col-lg-3 p-4 p-lg-3 d-flex justify-content-center "
                            data-item={JSON.stringify(item)}
                            onClick={(e) => {
                              videoModelOpen(e);
                            }}
                            onMouseOver={hoverInVideo}
                            onMouseOut={hoverOutVideo}
                          >
                            <div
                              className="rounded col-10 col-lg-12 shadow videoPlaceholder"
                              key={item._id}
                            >
                              <div style={{ height: "173px" }}>
                                <video
                                  className="col-12 pt-3 pt-lg-1 multiVideo"
                                  loop
                                  muted
                                  key={item[selectedColor].location}
                                >
                                  <source
                                    // key={item[selectedColor].location}
                                    src={
                                      "location" in item[selectedColor]
                                        ? item[
                                            selectedColor
                                          ].location.startsWith("https://s3.")
                                          ? item[selectedColor].location
                                          : item[selectedColor].location
                                              .replace(
                                                "/assets.pixominia.com.",
                                                "/"
                                              )
                                              .replace(
                                                ".com/",
                                                ".com/assets.pixominia.com/"
                                              )
                                        : `${BACKEND_URL}/uploads/${item[selectedColor].filename}`
                                    }
                                  />
                                </video>
                              </div>

                              <div className="d-flex col-12 justify-content-between col-12 py-2 px-2 border-top fs-5">
                                <span className="fw-semibold">
                                  {" "}
                                  {item.name.length > 15
                                    ? item.name.slice(0, 15) + ".."
                                    : item.name}
                                </span>
                                <FontAwesome
                                  name="download"
                                  role="button"
                                  className="fas fa-lg pt-2 fa-download"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {allTransitions.length < 1 && (
                    <h2 className="text-center mb-5"> {videoMsg} </h2>
                  )}
                </>
              )}
            </div>
          </div>
          {!searchKey ? (
            <div className="col-12 d-flex justify-content-center   p-3">
              {currentPage !== 1 ? (
                <button
                  className="mx-3 bg-color border-color text-light px-2 rounded fs-5 py-1"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Prev
                </button>
              ) : null}
              {renderPagination()}
              <button
                className="mx-3 bg-color border-color text-light px-2 rounded fs-5 py-1"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          ) : null}
        </div>

        <div
          id="video-model"
          className="d-none align-items-center p-2"
          style={{
            position: "fixed",
            inset: "0",
            background: "rgba(0,0,0,.5)",
          }}
        >
          <div
            style={{ position: "fixed", inset: "0", zIndex: "-1" }}
            onClick={() => videoModelClose()}
          ></div>
          <div className="container col-10 col-lg-7 rounded bg-white pb-4 px-3 px-sm-4">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between px-2 py-4 border-bottom border-2">
                  <div className="d-flex align-items-center">
                    <span className="ms-3 fw-semibold fs-2">
                      {selectedObject ? selectedObject.name : "Vincetn"}
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-fill justify-content-end">
                    {selectedObject &&
                    selectedObjectColor in selectedObject &&
                    (("rarfile" in selectedObject[selectedObjectColor] &&
                      ("filename" in
                        selectedObject[selectedObjectColor].rarfile ||
                        "location" in
                          selectedObject[selectedObjectColor].rarfile)) ||
                      ("imagegif" in selectedObject[selectedObjectColor] &&
                        ("originalname" in
                          selectedObject[selectedObjectColor].imagegif ||
                          "location" in
                            selectedObject[selectedObjectColor].imagegif))) ? (
                      isLoggedIn ? (
                        <>
                          <div className="nav-item ">
                            <div
                              className="nav-link  bg-color mx-2 rounded text-white px-3 py-2 fw-semibold"
                              role="button"
                            >
                              <a
                                download={
                                  "imagegif" in
                                  selectedObject[selectedObjectColor]
                                    ? selectedObject[selectedObjectColor]
                                        .imagegif.originalname
                                    : "default_filename.extension"
                                }
                                href={
                                  selectedObject &&
                                  selectedObjectColor !== "" &&
                                  selectedObjectColor in selectedObject
                                    ? "rarfile" in
                                        selectedObject[selectedObjectColor] &&
                                      "location" in
                                        selectedObject[selectedObjectColor]
                                          .rarfile
                                      ? selectedObject[
                                          selectedObjectColor
                                        ].rarfile.location.startsWith(
                                          "https://s3."
                                        )
                                        ? selectedObject[selectedObjectColor]
                                            .rarfile.location
                                        : selectedObject[
                                            selectedObjectColor
                                          ].rarfile.location
                                            .replace(
                                              "/assets.pixominia.com.",
                                              "/"
                                            )
                                            .replace(
                                              ".com/",
                                              ".com/assets.pixominia.com/"
                                            )
                                      : "imagegif" in
                                          selectedObject[selectedObjectColor] &&
                                        "location" in
                                          selectedObject[selectedObjectColor]
                                            .imagegif
                                      ? selectedObject[
                                          selectedObjectColor
                                        ].imagegif.location.startsWith(
                                          "https://s3."
                                        )
                                        ? selectedObject[selectedObjectColor]
                                            .imagegif.location
                                        : selectedObject[
                                            selectedObjectColor
                                          ].imagegif.location
                                            .replace(
                                              "/assets.pixominia.com.",
                                              "/"
                                            )
                                            .replace(
                                              ".com/",
                                              ".com/assets.pixominia.com/"
                                            )
                                      : `${BACKEND_URL}/uploads/${selectedObject[selectedObjectColor].rarfile.filename}`
                                    : "/"
                                }
                                className="link-none"
                                onClick={(e) => handleDownload(e)}
                              >
                                {" "}
                                Download
                              </a>
                            </div>
                            {downloading ? (
                              <div className="progress w-75 mx-auto mt-1">
                                <div
                                  id="download-progress-bar"
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  style={{
                                    width: "0%",
                                    backgroundColor: "green",
                                  }}
                                >
                                  0%
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="nav-item ">
                            <div
                              className="nav-link bg-info mx-2 rounded text-white px-3 py-2 fw-semibold"
                              role="button"
                            >
                              <a href={"/login"} className="link-none">
                                {" "}
                                Download
                              </a>
                              {
                                <Link
                                  role="button"
                                  to="/login"
                                  className="link-none"
                                ></Link>
                              }
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <video id="mainVideo" className="col-12 col-xl-10" autoPlay loop>
                <source
                  id="video-src"
                  type="video/mp4"
                  src={
                    selectedObject &&
                    selectedObjectColor !== "" &&
                    selectedObjectColor in selectedObject
                      ? "location" in selectedObject[selectedObjectColor]
                        ? selectedObject[
                            selectedObjectColor
                          ].location.startsWith("https://s3.")
                          ? selectedObject[selectedObjectColor].location
                          : selectedObject[selectedObjectColor].location
                              .replace("/assets.pixominia.com.", "/")
                              .replace(".com/", ".com/assets.pixominia.com/")
                        : `${process.env.REACT_APP_BACKEND_URL}/uploads/${selectedObject[selectedObjectColor].filename}`
                      : "/"
                  }
                />
              </video>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center fs-3">
                <FontAwesome
                  name="play"
                  role="button"
                  id="playPauseBtn"
                  className="fab fa-pause  me-3"
                  onClick={() => {
                    togglePlay();
                  }}
                />
                <div
                  className="flex-fill bg-dark mx-2"
                  style={{ height: "3px" }}
                >
                  <div
                    className="bg-color"
                    style={{
                      width: `${(currentTime / videoDuration) * 100}%`, // Calculate the width based on currentTime and videoDuration
                      height: "100%",
                    }}
                  ></div>
                </div>
                {/* <span>
                  {formatTime(currentTime)} / {formatTime(videoDuration)}
                </span>{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
