import apiClient from "./index";

export async function uploadFile(data) {
  try {
    console.warn(data.toString);
    let response = await apiClient.post("/upload_file/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    console.log("error in upload file :::: ", err);
    return err.response;
  }
}

export async function addVideoApi(data) {
  try {
    let response = await apiClient.post("/", data);
    return response;
  } catch (err) {
    console.log("error in createFile ::::: ", err);
    return err.response;
  }
}

export async function getAllFile(data) {
  try {
    let response = await apiClient.get("/");
    return response;
  } catch (err) {
    console.log("error in getAllFile ::::: ", err);
    return err.response;
  }
}

export async function getAllTransition(page = 1) {
  try {
    let response = await apiClient.get(
      `/transition?page=${page}`
    );
    return response;
  } catch (err) {
    console.log("error in getAllTransition ::::: ", err);
    return err.response;
  }
}

export async function getAllObject(page = 1) {
  try {
    let response = await apiClient.get(`/object?page=${page}`);
    return response;
  } catch (err) {
    console.log("error in getAllObject ::::: ", err);
    return err.response;
  }
}

export async function getAllBackground(page = 1) {
  try {
    let response = await apiClient.get(`/background?page=${page}`);
    return response;
  } catch (err) {
    console.log("error in getAllBackground ::::: ", err);
    return err.response;
  }
}

export async function searchVideo({ query, currentTab }) {
  try {
    // let response = await apiClient.get(
    //   `/searchVideos?query=${query}&currentTab=${currentTab}`
    // );
    let response = await apiClient.get(
      `/searchVideos?query=${query}`
    );
    return response.data;
  } catch (err) {
    console.log("error in searchVideo API ::::: ", err);
    return err.response;
  }
}

export async function getVideoById(id) {
  try {
    let response = await apiClient.get(`/${id}`);
    return response;
  } catch (err) {
    console.log("error in getVideoById ::::: ", err);
    return err.response;
  }
}
