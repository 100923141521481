import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import * as VIDEO_API from "../../api/videoapi";
import apiClient from "../../api";

let initialState = {
  progressBars: {
    blueMp4Bar: false,
    blueRarBar: false,
    redMp4Bar: false,
    redRarBar: false,
    colorMp4Bar: false,
    colorRarBar: false,
  },
  videoObj: {
    name: "",
    type: "",
    tags: [],
    blue: {},
    red: {},
    color: {},
  },
  totalPages: 0,
  searchedVideos: [],
  videoMsg: "",
  allVideos: [],
  allTransitions: [],
  allBackgrounds: [],
  allObjects: [],
  sortedVideos: {
    object: {
      red: [],
      blue: [],
      color: [],
    },
    transtition: {
      red: [],
      blue: [],
      color: [],
    },
    background: {
      red: [],
      blue: [],
      color: [],
    },
  },
};

export const getAllVideos = createAsyncThunk(
  "video/getAllVideos",
  async (data) => {
    const response = await VIDEO_API.getAllFile(data);
    return {
      videos: response.data.data,
    };
  }
);

export const getAllTransitions = createAsyncThunk(
  "video/getAllTransitions",
  async ({ page = 1 } = {}) => {
    const response = await VIDEO_API.getAllTransition(page);
    return {
      transitions: response.data.data.transitions,
      totalPages: response.data.totalPages,
    };
  }
);

export const getAllObjects = createAsyncThunk(
  "video/getAllObjects",
  async ({ page = 1 } = {}) => {
    const response = await VIDEO_API.getAllObject(page);
    return {
      objects: response.data.data.objects,
      totalPages: response.data.totalPages,
    };
  }
);

export const getAllBackgrounds = createAsyncThunk(
  "video/getAllBackgrounds",
  async ({ page = 1 } = {}) => {
    const response = await VIDEO_API.getAllBackground(page);
    return {
      backgrounds: response.data.data.backgrounds,
      totalPages: response.data.totalPages,
    };
  }
);

export const searchVideos = createAsyncThunk(
  "video/searchVideos",
  async (query) => {
    const response = await VIDEO_API.searchVideo(query);
    // console.log(response.data);
    return response.data;
  }
);

export const getVideoById = createAsyncThunk(
  "video/getVideoById",
  async (name) => {
    const response = await VIDEO_API.getVideoById(name);
    return response.data.data; // Return the video data directly
  }
);

export const uploadSingleFile = createAsyncThunk(
  "video/uploadFile",
  async (data) => {
    let response = await VIDEO_API.uploadFile(data.formData);
    var { fileType, vtype, mainKey } = data;
    // return response
    return {
      keyName: vtype,
      file: response.data.file,
      mainKey: mainKey,
      status: response.data.status,
      fileType: fileType,
    };
  }
);
export const addVideo = createAsyncThunk(
  "video/addVideo",
  async (data, { rejectWithValue }) => {
    try {
      if (data.tags && data.tags.length) {
        data.tags = data.tags.map((tag) => tag.toLowerCase()); // Convert tags to lowercase
      }
      console.log(data.tags);
      const response = await VIDEO_API.addVideoApi(data);
      return response;
    } catch (error) {
      console.log("Error in addVideo: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setColorObj: (state, action) => {
      console.log(action.payload);
      // state.videoObj[action.payload.color][action.payload.keyName] = action.payload.data
    },
    setNameVideoForObj: (state, action) => {
      state.videoObj.name = action.payload.name;
    },
    setTypeForVideoObj: (state, action) => {
      state.videoObj.type = action.payload.type;
    },
    setTagsForVideoObject: (state, action) => {
      state.videoObj.tags = action.payload.tags;
    },
    resetVideoObj: (state, action) => {
      state.videoObj = initialState.videoObj;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle searchVideos lifecycle
      .addCase(searchVideos.pending, (state) => {
        state.loading = true;
        state.videoMsg = (
          <div className="d-flex justify-content-center">
            <div className="text-info spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        );
        state.searchedVideos = [];
        state.error = null;
      })
      .addCase(searchVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.searchedVideos = action.payload;
        state.videoMsg = action.payload.length > 0 ? "" : "No Videos";
      })
      .addCase(searchVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error fetching videos";
        state.videoMsg = state.error;
      });
    builder.addCase(uploadSingleFile.fulfilled, (state, action) => {
      if (action.payload.fileType === "mp4") {
        state.videoObj[action.payload.mainKey] = action.payload.file;
      } else {
        state.videoObj[action.payload.mainKey][action.payload.keyName] =
          action.payload.file;
      }
    });
    builder.addCase(uploadSingleFile.pending, (state, action) => {
      state.progressBars.blueMp4Bar = true;
    });
    builder.addCase(getAllVideos.fulfilled, (state, action) => {
      state.allVideos = action.payload.videos;
    });
    builder
      .addCase(getAllTransitions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllTransitions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allTransitions = action.payload.transitions;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getAllTransitions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder.addCase(getAllObjects.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.allObjects = action.payload.objects;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getAllBackgrounds.fulfilled, (state, action) => {
      state.allBackgrounds = action.payload.backgrounds;
      state.totalPages = action.payload.totalPages;
    });
    builder
      .addCase(getVideoById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getVideoById.fulfilled, (state, action) => {
        const videoData = action.payload;
        state.video = videoData;
        state.title = videoData.title;
        state.paragraph = videoData.paragraph;
        state.metaTags = videoData.metaTags;
        state.description = videoData.description;
        state.keywords = videoData.keywords;
        state.isLoading = false;
      })
      .addCase(getVideoById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default videoSlice;
