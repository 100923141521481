import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVideos,
  getVideoById,
  searchVideos,
} from "../store/slices/videoSlice";
import Logo from "../images/Logo.svg";
import { BACKEND_URL } from "../utils";
import { userActions } from "../store";
import New from "../images/new_icon_blink.gif";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";

function VideoPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [colorSlctd, setColorSlctd] = useState(false);
  const { allVideos, isLoading } = useSelector((state) => state.video);
  const video = useSelector((state) => state.video.video);
  const { searchedVideos, videoMsg, loading } = useSelector(
    (state) => state.video
  );
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedColor, setSelectedColor] = useState("color");
  const [shareUrl, setShareUrl] = useState("");
  const [metaData, setMetaData] = useState({});
  const inputRef = useRef(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [selectedObjectColor, setSelectedObjectColor] = useState("color");
  const [profilepic, setprofilepic] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  const handleRequestClick = () => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      navigate("/request-your-need");
    }
    setTimeout(function () {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  };
  function hoverInVideo(e) {
    const videoElement = e.currentTarget.getElementsByTagName("video")[0];
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play().catch((error) => {});
      }
    }
  }
  function hoverOutVideo(e) {
    const videoElement = e.currentTarget.getElementsByTagName("video")[0];
    if (videoElement && !videoElement.paused) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }
  useEffect(() => {
    dispatch(getAllVideos());
    dispatch(getVideoById(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (video) {
      setShareUrl(window.location.href);
      setSelectedObject(video.video);
      const { title, description, keywords, metaTags, paragraph } = video;
      setMetaData({
        title,
        createdAt: video.video.createdAt,
        id: video.video._id,
        description: description || "",
        paragraph: paragraph || "",
        keywords: keywords ? keywords.join(", ") : "",
        metaTags: metaTags || [],
      });
    }
  }, [video]);
  useEffect(() => {
    // const currentVideo = allVideos.find(
    //   (video) => video.id === _id
    // );
    if (selectedObject) {
      const related = allVideos.filter(
        (v) =>
          v._id !== selectedObject.name &&
          v.tags.some((tag) => selectedObject.tags.includes(tag))
      );
      setRelatedVideos(related);
      setShareUrl(window.location.href);
    }
  }, [allVideos, id]);
  useEffect(() => {
    const videoElement = document.getElementById("mainVideo");
    if (selectedObject && videoElement) {
      videoElement.load();

      const handleLoadedMetadata = () => {
        setVideoDuration(videoElement.duration);
      };

      const handleTimeUpdate = () => {
        setCurrentTime(videoElement.currentTime);
      };

      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
    return () => {
      const handleLoadedMetadata = () => {
        setVideoDuration(videoElement.duration);
      };
      const handleTimeUpdate = () => {
        setCurrentTime(videoElement.currentTime);
      };
      // Cleanup in case selectedObject changes or component unmounts
      if (videoElement) {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [selectedObject]);
  useEffect(() => {
    var user = window.localStorage.getItem("user");
    var userdata =
      user === null ? null : JSON.parse(window.localStorage.getItem("user"));
    setprofilepic(`http://bootdey.com/img/Content/avatar/avatar7.png`);
    var usertoken = window.localStorage.getItem("accessToken");
    if (usertoken !== null && usertoken !== "") {
      setisLoggedIn(true);
    }
    const handleKeyPress = (event) => {
      const target = event.target;
      const isInputField =
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable;
      if (event.code === "Space" && !isInputField) {
        event.preventDefault();
        togglePlay();
      }
    };
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    if (metaData.title) {
      document.title = metaData.title;
    }
  }, [metaData]);
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "VideoObject",
      name: metaData.title,
      description: metaData.description,
      // thumbnailUrl: metaData.thumbnailUrl,
      uploadDate: metaData.createdAt,
      contentUrl: shareUrl,
      embedUrl: shareUrl,
      duration: `PT${Math.floor(videoDuration / 60)}M${Math.floor(
        videoDuration % 60
      )}S`,
    });

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [metaData, selectedObject, shareUrl, videoDuration]);
  const handlePageChange = (name, id) => {
    const formatNameForUrl = (name) => {
      return name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "");
    };
    const formattedName = formatNameForUrl(name);
    // console.log(formattedName);
    navigate(`/video/${formattedName}/${id}`);
  };
  function handleSliderChange(e) {
    const videoElement = document.getElementById("mainVideo");
    videoElement.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  }
  function handleDownload(e) {
    e.preventDefault();
    const selectedFile =
      "imagegif" in selectedObject[selectedObjectColor]
        ? selectedObject[selectedObjectColor].imagegif
        : null;

    if (selectedFile) {
      if (!downloading) {
        setDownloading(true);
        e.target.innerHTML = "0% Downloading...";
        e.target.setAttribute("disabled", "disabled");

        let link = e.currentTarget.getAttribute("href");

        fetch(link)
          .then((response) => {
            const contentLength = response.headers.get("content-length");
            if (!contentLength) {
              throw new Error("Content-Length response header is missing.");
            }

            const totalBytes = parseInt(contentLength, 10);
            let receivedBytes = 0;

            const reader = response.body.getReader();
            const stream = new ReadableStream({
              start(controller) {
                function push() {
                  reader.read().then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }

                    receivedBytes += value.length;
                    const percentage = Math.floor(
                      (receivedBytes / totalBytes) * 100
                    );
                    updateProgress(percentage, e.target);
                    // e.target.innerHTML = `${percentage}% Downloading...`;
                    e.target.innerHTML = `Downloading...`;

                    controller.enqueue(value);
                    push();
                  });
                }
                push();
              },
            });

            return new Response(stream);
          })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.setAttribute("download", selectedFile.originalname);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            e.target.innerHTML = "Download";
            e.target.removeAttribute("disabled");
            setDownloading(false);
          })
          .catch((err) => {
            console.error("Download failed", err);
            e.target.innerHTML = "Download Failed";
            setDownloading(false);
          });
      }
    }
  }
  const debouncedSearch = useCallback(
    debounce(({ query }) => {
      if (query) {
        dispatch(searchVideos({ query }))
          .unwrap()
          .then((result) => {
            console.log("Videos fetched: ", result);
          })
          .catch((error) => {
            console.error("Error fetching videos: ", error);
          });
      }
    }, 250),
    [dispatch]
  );

  const handleSearch = (searchedKey) => {
    const inputText =
      searchedKey === null
        ? inputRef.current.value.toLowerCase()
        : searchedKey.toLowerCase();
    setSearchKey(inputText);
    // setVideoMsg("Loading...");
    debouncedSearch({ query: inputText });
    window.scrollTo({ top: 450, left: 0, behavior: "smooth" });
  };
  function togglePlay() {
    const videoElement = document.getElementById("mainVideo");
    const playPauseBtn = document.getElementById("playPauseBtn");

    if (videoElement.paused) {
      videoElement.play();
      playPauseBtn.classList.add("fa-pause");
      playPauseBtn.classList.remove("fa-play");
    } else {
      videoElement.pause();
      playPauseBtn.classList.add("fa-play");
      playPauseBtn.classList.remove("fa-pause");
    }
  }
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        // alert("URL copied to clipboard!");
        toast["success"]("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  function updateProgress(progress, button) {
    const progressBar = document.getElementById("download-progress-bar");
    if (progressBar) {
      progressBar.style.width = `${progress}%`;
      progressBar.innerHTML = `${Math.round(progress)}%`;
    }

    // Update the button text with the progress
    button.innerHTML = `Downloading ${Math.round(progress)}%...`;
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="text-color spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  // if (error) {
  //   return <div>Error: {error}</div>;
  // }
  return (
    <div className="bg-white">
      <Helmet key={metaData.id}>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        {(metaData.metaTags || []).map((tag, index) =>
          tag.name && tag.content ? (
            <meta
              key={index}
              property={`og:${tag.name}`}
              content={tag.content}
            />
          ) : null
        )}
      </Helmet>

      <div className="container-fluid position-fixed top-0">
        <div
          className="row py-3 align-items-center"
          style={{ backgroundColor: "#80e8d4" }}
        >
          <div className="col-4 d-flex justify-content-center">
            <Link to="/">
              <img
                src={Logo}
                style={{
                  height: "44px",
                  filter: "brightness(0) invert(1)",
                  width: "auto",
                }}
                alt="Pixominia Logo"
              />
            </Link>
          </div>
          <div className="col-8 col-lg-4">
            <div className="d-flex justify-content-evenly align-items-center col-12 px-2 py-3 rounded border bg-white">
              <input
                className="flex-fill border-0 bg-transparent fs-5 ps-3"
                ref={inputRef}
                placeholder="Search..."
                type="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(null, e.currentTarget.value, null);
                  } else {
                  }
                }}
              />
              <label> </label>
              <i
                className="fa fa-search fa-lg"
                onClick={() => handleSearch(null, null, null)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          <div className="col-4 d-none d-lg-flex justify-content-center">
            {!isLoggedIn ? (
              <>
                <Link
                  role="button"
                  to="/signup"
                  className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
                >
                  Sign up
                </Link>
                <Link
                  role="button"
                  to="/login"
                  className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
                >
                  Login
                </Link>
              </>
            ) : (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="d-none d-lg-block"
                    src={New}
                    style={{
                      height: "13px",
                      position: "relative",
                      zIndex: 1,
                      top: -5,
                      left: 15,
                      cursor: "none",
                    }}
                    alt="New Gif"
                  />
                </div>
                <Link
                  to={`/request-your-need`}
                  className="text-reset text-decoration-none me-3 py-2 px-3 fs-5 fw-semibold d-none d-lg-block"
                >
                  Request Your Need
                </Link>
                <div className="dropdown">
                  <div
                    className="btn btn-secondary rounded-circle"
                    style={{
                      height: "44px",
                      width: "44px",
                      backgroundSize: "cover",
                      backgroundImage:
                        profilepic != null
                          ? `url(${profilepic})`
                          : "url('http://bootdey.com/img/Content/avatar/avatar1.png')",
                    }}
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to="/user-profile">
                        Edit Profile
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          dispatch(
                            userActions.setLoginResponse({ empty: true })
                          );
                          window.localStorage.clear();
                          window.location.reload(true);
                        }}
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 pt-5">
        <div className="row pt-5 justify-content-center justify-content-lg-center align-items-center">
          <div className="col-11 rounded col-lg-6 bg-white shadow me-0 me-lg-5">
            <div className="row justify-content-center align-items-center bg-white">
              <video className="col-12 p-0" id="mainVideo" autoPlay loop muted>
                <source
                  type="video/mp4"
                  src={
                    selectedObject &&
                    selectedObjectColor !== "" &&
                    selectedObjectColor in selectedObject
                      ? "location" in selectedObject[selectedObjectColor]
                        ? selectedObject[
                            selectedObjectColor
                          ].location.startsWith("https://s3.")
                          ? selectedObject[selectedObjectColor].location
                          : selectedObject[selectedObjectColor].location
                              .replace("/assets.pixominia.com.", "/")
                              .replace(".com/", ".com/assets.pixominia.com/")
                        : `${process.env.REACT_APP_BACKEND_URL}/uploads/${selectedObject[selectedObjectColor].filename}`
                      : "/"
                  }
                />
              </video>
            </div>
            <div className="row">
              <div
                className="col-12 d-flex justify-content-between align-items-center fs-3 py-2"
                style={{ background: "rgb(13, 202, 240,0.15)" }}
              >
                <FontAwesome
                  role="button"
                  id="playPauseBtn"
                  name="play"
                  className="fab fa-pause me-3"
                  onClick={() => {
                    togglePlay();
                  }}
                />
                <input
                  type="range"
                  min="0"
                  max={videoDuration}
                  value={currentTime}
                  onChange={handleSliderChange}
                  className="flex-fill mx-2"
                  style={{ height: "3px", background: "#089cc4" }}
                />
              </div>
            </div>
          </div>
          <div className="col-8 col-lg-2 mt-3 mt-lg-0 ms-0 ms-lg-5">
            <div className="row bg-white shadow rounded">
              <div className="d-flex col-12 my-3">
                <h1 className="ms-4 fw-semibold fs-3">
                  {selectedObject ? selectedObject.name : "Animation Name"}
                </h1>
              </div>
              <div className="d-flex col-12">
                {selectedObject &&
                selectedObjectColor in selectedObject &&
                (("rarfile" in selectedObject[selectedObjectColor] &&
                  ("filename" in selectedObject[selectedObjectColor].rarfile ||
                    "location" in
                      selectedObject[selectedObjectColor].rarfile)) ||
                  ("imagegif" in selectedObject[selectedObjectColor] &&
                    ("originalname" in
                      selectedObject[selectedObjectColor].imagegif ||
                      "location" in
                        selectedObject[selectedObjectColor].imagegif))) ? (
                  isLoggedIn ? (
                    <>
                      <a
                        rel="noopener"
                        download={
                          "imagegif" in selectedObject[selectedObjectColor]
                            ? selectedObject[selectedObjectColor].imagegif
                                .originalname
                            : "default_filename.extension"
                        }
                        href={
                          selectedObject &&
                          selectedObjectColor !== "" &&
                          selectedObjectColor in selectedObject
                            ? "rarfile" in
                                selectedObject[selectedObjectColor] &&
                              "location" in
                                selectedObject[selectedObjectColor].rarfile
                              ? selectedObject[
                                  selectedObjectColor
                                ].rarfile.location.startsWith("https://s3.")
                                ? selectedObject[selectedObjectColor].rarfile
                                    .location
                                : selectedObject[
                                    selectedObjectColor
                                  ].rarfile.location
                                    .replace("/assets.pixominia.com.", "/")
                                    .replace(
                                      ".com/",
                                      ".com/assets.pixominia.com/"
                                    )
                              : "imagegif" in
                                  selectedObject[selectedObjectColor] &&
                                "location" in
                                  selectedObject[selectedObjectColor].imagegif
                              ? selectedObject[
                                  selectedObjectColor
                                ].imagegif.location.startsWith("https://s3.")
                                ? selectedObject[selectedObjectColor].imagegif
                                    .location
                                : selectedObject[
                                    selectedObjectColor
                                  ].imagegif.location
                                    .replace("/assets.pixominia.com.", "/")
                                    .replace(
                                      ".com/",
                                      ".com/assets.pixominia.com/"
                                    )
                              : `${BACKEND_URL}/uploads/${selectedObject[selectedObjectColor].rarfile.filename}`
                            : "/"
                        }
                        className="rounded bg-color fs-2 fw-semibold py-2 w-100 text-center link-none mx-4"
                        role="button"
                        onClick={(e) => handleDownload(e)}
                      >
                        {" "}
                        Download
                      </a>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="rounded bg-color fs-2 fw-semibold py-2 w-100 text-center link-none mx-4"
                        role="button"
                      >
                        {" "}
                        Download
                      </Link>
                    </>
                  )
                ) : (
                  ""
                )}
              </div>
              {downloading ? (
                <div className="progress mt-2 w-75 mx-auto">
                  <div
                    id="download-progress-bar"
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: "0%", backgroundColor: "green" }}
                  >
                    0%
                  </div>
                </div>
              ) : null}
              <div className="d-flex col-12 my-3 justify-content-center">
                <button
                  type="button"
                  className="btn py-2 px-3 text-center fs-4 border border-info text-info"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Share
                  <FontAwesome
                    name="share"
                    role="button"
                    className="fa-solid fa-share ps-2 fa-lg"
                  />
                </button>

                <div
                  className="modal fade"
                  id="staticBackdrop"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                >
                  <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                          Share
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body my-3">
                        <div className="d-flex align-items-center justify-content-center mb-4">
                          <input
                            type="text"
                            className="form-control me-2 w-75"
                            readOnly
                            value={shareUrl}
                          />
                          <button
                            className="btn btn-color text-white"
                            onClick={copyToClipboard}
                          >
                            Copy URL
                          </button>
                        </div>
                        {/* <div className="d-flex justify-content-center">
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                              shareUrl
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="me-4"
                          >
                            <FontAwesomeIcon
                              icon={faFacebookF}
                              style={{ fontSize: "2.5rem", color: "#1877F2" }}
                            />
                          </a>
                          <a
                            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                              shareUrl
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="me-4"
                          >
                            <FontAwesomeIcon
                              icon={faTwitter}
                              style={{ fontSize: "2.5rem", color: "#1DA1F2" }}
                            />
                          </a>
                          <a
                            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                              shareUrl
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="me-4"
                          >
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              style={{ fontSize: "2.5rem", color: "#25D366" }}
                            />
                          </a>
                          <a
                            href={`https://www.instagram.com/share?url=${encodeURIComponent(
                              shareUrl
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faInstagram}
                              style={{ fontSize: "2.5rem", color: "#E4405F" }}
                            />
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="">
            <h2 className="fs-3">
              {searchedVideos.length > 0 ? "Searched Videos" : "Related Videos"}
            </h2>
            <div className="col-12 d-flex flex-wrap justify-content-start">
              {searchKey !== "" || colorSlctd ? (
                <>
                  {loading ? (
                    <div className="spinner-border text-color" role="status">
                      <span className="sr-only">{videoMsg}</span>
                    </div>
                  ) : null}
                  {searchedVideos.length > 0 &&
                    searchedVideos.map((item, index) => {
                      if (selectedColor in item) {
                        return (
                          <div
                            onClick={() =>
                              handlePageChange(item.name, item._id)
                            }
                            className="col-12 col-sm-6 col-md-4 col-lg-3"
                          >
                            <div
                              key={item._id}
                              className="pt-5 p-lg-3 d-flex justify-content-center link-none text-dark"
                              data-item={JSON.stringify(item)}
                              onMouseOver={hoverInVideo}
                              onMouseOut={hoverOutVideo}
                            >
                              <div
                                className="rounded col-10 col-lg-12 videoPlaceholder"
                                key={item._id}
                              >
                                <div>
                                  <video
                                    preload="auto"
                                    className="col-12 pt-3 pt-lg-1 multiVideo"
                                    loop
                                    muted
                                    controls={false}
                                    key={item[selectedColor].location}
                                  >
                                    <source
                                      src={
                                        "location" in item[selectedColor]
                                          ? item[
                                              selectedColor
                                            ].location.startsWith("https://s3.")
                                            ? item[selectedColor].location
                                            : item[selectedColor].location
                                                .replace(
                                                  "/assets.pixominia.com.",
                                                  "/"
                                                )
                                                .replace(
                                                  ".com/",
                                                  ".com/assets.pixominia.com/"
                                                )
                                          : `${BACKEND_URL}/uploads/${item[selectedColor].filename}`
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                                <div className="d-flex col-12 justify-content-between col-12 py-2 px-2 border-top fs-5">
                                  <span className="fw-semibold">
                                    {item.name.length > 13
                                      ? item.name.slice(0, 13) + ".."
                                      : item.name}
                                  </span>
                                  <FontAwesome
                                    role="button"
                                    className="fa-solid fa-download pt-2 fa-lg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {searchedVideos.length < 1 && !loading && (
                    <div>
                      <div
                        onClick={handleRequestClick}
                        className="btn btn-info text-white btn-lg active"
                        role="button"
                        aria-pressed="true"
                      >
                        Request Your Need
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {relatedVideos.length > 0 ? (
                    relatedVideos.map((item, index) => {
                      return (
                        <div
                          onClick={() => handlePageChange(item.name, item._id)}
                          className="col-12 col-sm-6 col-md-4 col-lg-3"
                        >
                          <div
                            key={item._id}
                            className="pt-5 p-lg-3 d-flex justify-content-center link-none text-dark"
                            data-item={JSON.stringify(item)}
                            onMouseOver={hoverInVideo}
                            onMouseOut={hoverOutVideo}
                          >
                            <div
                              className="rounded col-10 col-lg-12 videoPlaceholder"
                              key={item._id}
                            >
                              <div>
                                <video
                                  className="col-12 pt-3 pt-lg-1 multiVideo"
                                  loop
                                  muted
                                  controls={false}
                                  key={item._id}
                                >
                                  <source
                                    src={
                                      "location" in item[selectedColor]
                                        ? item[
                                            selectedColor
                                          ].location.startsWith("https://s3.")
                                          ? item[selectedColor].location
                                          : item[selectedColor].location
                                              .replace(
                                                "/assets.pixominia.com.",
                                                "/"
                                              )
                                              .replace(
                                                ".com/",
                                                ".com/assets.pixominia.com/"
                                              )
                                        : `${BACKEND_URL}/uploads/${item[selectedColor].filename}`
                                    }
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                              <div
                                className="d-flex col-12 justify-content-between col-12 py-2 px-2 border-top fs-5"
                                id="placeholder"
                              >
                                <span className="fw-semibold">
                                  {item.name.length > 13
                                    ? item.name.slice(0, 13) + ".."
                                    : item.name}
                                </span>
                                <FontAwesome
                                  name="download"
                                  role="button"
                                  className="fa-solid fa-download pt-2 fa-lg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <div
                        onClick={handleRequestClick}
                        className="btn btn-info text-white btn-lg active"
                        role="button"
                        aria-pressed="true"
                      >
                        Request Your Need
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <p class=" mt-4 mb-4 py-3 text-dark fs-5">{metaData.paragraph}</p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default VideoPage;
