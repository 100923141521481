import { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const AxiosContext = createContext();
let client = axios.create({
  baseURL: `https://api.pixominia.com/api/v1`,
  // baseURL: `http://localhost:9000/api/v1`,
});

export const AxiosProvider = ({ children }) => {
  let [isadmin, setadmin] = useState(false);
  const [userlist, setuserlist] = useState([]);
  const [videolist, setvideolist] = useState([]);
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalRequest, setTotalRequest] = useState(0);
  const [fulfillRequest, setFulfillRequest] = useState(0);
  const [pendingRequest, setPendingRequest] = useState(0);

  const [totalUser, setTotalUser] = useState(0);
  const [activeuser, setactiveuser] = useState(0);
  const [inactiveuser, setinactiveuser] = useState(0);

  const [totalObject, setTotalObject] = useState(0);
  const [totalCharacter, setTotalCharacter] = useState(0);
  const [totalBackground, setTotalBackground] = useState(0);

  const [dashboarddata, setDashboarddata] = useState({
    totalUser: 0,
    activeuser: 0,
    inactiveuser: 0,

    totalRequest: 0,
    fulfillRequest: 0,
    pendingRequest: 0,

    totalObject: 0,
    totalCharacter: 0,
    totalBackground: 0,
  });

  isadmin = localStorage.getItem("admin");

  const getData = async (endPoint, header) => {
    try {
      let response = await client.get(endPoint, header);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const postData = async (endPoint, body, headers) => {
    try {
      let response = await client.post(endPoint, body, headers);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteData = async (endPoint, id, header) => {
    try {
      const response = await client.delete(`${endPoint}${id}`, header ?? {});
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const putData = async (endPoint, id, header, body) => {
    try {
      const response = await client.put(`${endPoint}${id}`, header, body);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchdashboarddata = async () => {
    setLoading(true);
    try {
      await Promise.all([
        getvideolist(),
        getuserlist(),
        getrequestlist(),

        total_user_counter(),
        active_user_counter(),
        Inactive_user_counter(),

        total_request_counter(),
        fulfilled_request_counter(),
        pending_request_counter(),

        total_object_counter(),
        total_character_counter(),
        total_background_counter(),
      ]);
    } finally {
      setLoading(false);
    }
  };

  const userSaveKeys = async (data, header) => {
    try {
      const res = await postData(data, header);

      return res;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const adminloginApi = async (data) => {
    try {
      let res = await postData("/login", data);
      if (res.status === 200) {
        setadmin(true);
        localStorage.setItem("admin", "1");
      }

      return res;
    } catch (err) {
      console.log("error in loginUserApi::::", err);
      return err.response;
    }
  };
  const getuserlist = async () => {
    try {
      let res = await getData("/user/user_list");

      setuserlist(res.data.data);
    } catch (err) {
      console.log("error in loginUserApi::::", err);
    }
  };
  const getrequestlist = async () => {
    try {
      let res = await getData("/request/all-requests");

      setRequestList(res.data.requests);
    } catch (err) {
      console.log("error in loginUserApi::::", err);
    }
  };

  // Request Count's

  const total_request_counter = async () => {
    try {
      let res = await getData("/request/total-count");
      if (res.status === 200) {
        const total_count = res.data.data.total_request;
        setTotalRequest(total_count);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const fulfilled_request_counter = async () => {
    try {
      let res = await getData("/request/fulfilled-count");
      if (res.status === 200) {
        const fulfill_request = res.data.data.fulfill_request;
        setFulfillRequest(fulfill_request);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const pending_request_counter = async () => {
    try {
      let res = await getData("/request/pending-count");
      if (res.status === 200) {
        const pending_request = res.data.data.pending_request;
        setPendingRequest(pending_request);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const fulfillUserRequest = async (requestId, animationUrl) => {
    try {
      const res = await postData(`/request/request-fulfill/${requestId}`, {
        animationUrl,
      });
      if (res.status === 200) {
        toast.success("Request fulfilled successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getrequestlist();
      }
    } catch (err) {
      console.log("Error fulfilling request:", err);
      toast.error("Failed to fulfill request", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteuser = async (id) => {
    try {
      let res = await deleteData("/user/", id);
      // console.log(res);
    } catch (err) {
      console.log("error in loginUserApi::::", err);
    }
  };
  const adminUpdateUserApi = async (userid, data) => {
    try {
      let res = await postData(`/user/${userid}`, data);
      if (res.status === 200) {
        getuserlist();
      }

      return res;
    } catch (err) {
      console.log("error in loginUserApi::::", err);
      return err.response;
    }
  };
  const getvideolist = async () => {
    try {
      let res = await getData("/");
      if (res.status === 200) {
        const list = res.data.data;
        setvideolist(res.data.data);
      }

      return res;
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };

  // User Count's

  const total_user_counter = async () => {
    try {
      let res = await getData("/user/total_user_counter");
      if (res.status === 200) {
        const total_users = res.data.total_users;
        setTotalUser(total_users);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const Inactive_user_counter = async () => {
    try {
      let res = await getData("/user/Inactive_user_counter");
      if (res.status === 200) {
        const Inactive_users = res.data.Inactive_users;
        setinactiveuser(Inactive_users);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const active_user_counter = async () => {
    try {
      let res = await getData("/user/active_user_counter");
      if (res.status === 200) {
        const active_users = res.data.active_users;
        setactiveuser(active_users);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };

  // Request Count's

  const total_object_counter = async () => {
    try {
      let res = await getData("/count/object-counter");
      if (res.status === 200) {
        const object_count = res.data.object_count;
        // console.log(res.data.object_count);
        setTotalObject(object_count);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const total_character_counter = async () => {
    try {
      let res = await getData("/count/character-counter");
      if (res.status === 200) {
        const character_count = res.data.character_count;
        // console.log(res.data.character_count);
        setTotalCharacter(character_count);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };
  const total_background_counter = async () => {
    try {
      let res = await getData("/count/background-counter");
      if (res.status === 200) {
        const background_count = res.data.background_count;
        // console.log(res.data.background_count);
        setTotalBackground(background_count);
      }
    } catch (err) {
      console.log("error in loading::::", err);
      return err.response;
    }
  };

  const deletebyId = async (id) => {
    try {
      let res = await deleteData("?videoId=" + id, "");
      if (res.status === 204) {
        getvideolist();
        toast["success"]("success");
      } else {
        toast["error"](res.message);
      }
      console.log(res);
    } catch (err) {
      console.log("error in deleteApi::::", err);
    }
  };

  const updatebyId = async (id, data) => {
    try {
      let res = await postData(`/updateTour/${id}`, data);
      if (res.status === 200) {
        await getvideolist();
        toast["success"]("success");
      } else {
        toast["error"](res.message);
      }
    } catch (err) {
      toast["error"]("error");

      console.log("error in loginUserApi::::", err);
    }
  };

  const updateProfile = async (temp, selectedFile) => {
    const formData = new FormData();
    if (selectedFile != null) formData.set("profile_image", selectedFile);
    if (temp.FirstName != null && temp.FirstName !== "")
      formData.set("first_name", temp.FirstName);
    if (temp.LastName != null && temp.LastName !== "")
      formData.set("last_name", temp.LastName);
    if (temp.EmailAddress != null && temp.EmailAddress !== "")
      formData.set("email", temp.EmailAddress);
    if (temp.Phone != null && temp.Phone !== "")
      formData.set("mobileno", temp.Phone);
    if (temp.Birthday != null && temp.Birthday !== "")
      formData.set("dob", temp.Birthday);
    if (temp.oldPassword != null && temp.oldPassword !== "")
      formData.set("oldPassword", temp.oldPassword);
    if (temp.newPassword != null && temp.newPassword !== "")
      formData.set("password", temp.newPassword);

    var user = JSON.parse(window.localStorage.getItem("user"));
    console.log([...formData.entries()]);

    try {
      const response = await postData(`/user/${user._id}`, formData, {
        "Content-Type": "multipart/form-data",
      });
      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.localStorage.setItem("user", JSON.stringify(response?.data?.data));
      window.location.reload();
      // if (!response.data.) {
      //     throw new Error("Failed to update profile");
      // }

      // const data = await response.json();
      // console.log(data); // Success message from API
    } catch (error) {
      // console.log(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <AxiosContext.Provider
      value={{
        getData,
        postData,
        deleteData,
        putData,
        adminloginApi,
        getuserlist,
        getrequestlist,
        fulfillUserRequest,
        deleteuser,
        updatebyId,
        fetchdashboarddata,
        dashboarddata,
        adminUpdateUserApi,
        getvideolist,
        deletebyId,
        updateProfile,
        isadmin,
        userlist,
        requestList,

        totalRequest,
        fulfillRequest,
        pendingRequest,

        totalUser,
        activeuser,
        inactiveuser,

        totalObject,
        totalCharacter,
        totalBackground,

        videolist,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => useContext(AxiosContext);
