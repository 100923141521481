import React, { useEffect, useState } from "react";
import { useAxios } from "../../utils/axiosprovider";

export default function UsersList() {
  const { userlist, getuserlist, deleteuser, adminUpdateUserApi } = useAxios();
  const usersPerPage = 20;
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUserList, setFilteredUserList] = useState([]);

  useEffect(() => {
    getuserlist();
  }, [getuserlist]);

  useEffect(() => {
    setFilteredUserList(userlist);
  }, [userlist]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  const deleteuserfun = (id) => {
    deleteuser(id);
  };

  // State for editing user
  const [selecteduser, setuser] = useState({
    first_name: "",
    email: "",
    mobileno: "",
  });

  const [userid, setuserid] = useState(null);

  const handleState = (e) => {
    const { id, value } = e.currentTarget;
    setuser((prev) => ({ ...prev, [id]: value }));
  };

  const handleForm = (e) => {
    e.preventDefault();
    if (
      !selecteduser.first_name ||
      !selecteduser.email ||
      !selecteduser.mobileno
    ) {
      console.log("Some fields are missing");
      return;
    }
    adminUpdateUserApi(userid, selecteduser);
    editModelClose();
  };

  const totalPages = Math.ceil(filteredUserList.length / usersPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const paginationItems = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`btn btn-secondary mx-1 bg-info border-info ${
              i === currentPage ? "active bg-danger border-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      const startPage = Math.max(
        1,
        Math.min(
          currentPage - Math.floor(maxVisiblePages / 2),
          totalPages - maxVisiblePages + 1
        )
      );
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (startPage > 1) {
        paginationItems.push(
          <button
            key={1}
            className={`btn btn-secondary mx-1 bg-info border-info ${
              1 === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
        if (startPage > 2) {
          paginationItems.push(
            <span key="ellipsis-start" className="mx-2">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`btn btn-secondary mx-2 bg-info border-info ${
              i === currentPage ? "active border-danger bg-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push(
            <span key="ellipsis-end" className="mx-1">
              ...
            </span>
          );
        }
        paginationItems.push(
          <button
            key={totalPages}
            className={`btn btn-secondary mx-2 bg-info border-info ${
              totalPages === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
      }
    }
    return paginationItems;
  };

  const getSerialNumber = (index) => {
    return (currentPage - 1) * usersPerPage + index + 1;
  };

  const editModelClose = () => {
    document.getElementById("edit-model").classList.add("d-none");
  };

  const editModelOpen = (e) => {
    setuser({
      first_name: e.first_name,
      email: e.email,
      mobileno: e.mobileno,
    });
    setuserid(e._id);
    document.getElementById("edit-model").classList.remove("d-none");
  };

  const popupModelClose = () => {
    document.getElementById("popup-model").classList.add("d-none");
  };

  const popupModelOpen = () => {
    document.getElementById("popup-model").classList.remove("d-none");
  };

  return (
    <div className="content-wrapper px-5 text-secondary mt-5">
      <div
        id="edit-model"
        className="d-none align-items-center p-2"
        style={{
          position: "fixed",
          inset: "0",
          background: "rgba(0,0,0,.5)",
          zIndex: "1050",
        }}
      >
        <div
          style={{ position: "fixed", inset: "0", zIndex: "-1" }}
          onClick={editModelClose}
        ></div>
        <div
          className="container col-6 rounded bg-white py-5 px-1 px-sm-5"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="card-header">
            <h3> Edit User </h3>
          </div>
          <div className="card-body my-2">
            <form onSubmit={handleForm}>
              <div className="mb-2">
                <label htmlFor="first_name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  onChange={handleState}
                  value={selecteduser.first_name}
                  className="form-control"
                  id="first_name"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  onChange={handleState}
                  value={selecteduser.email}
                  className="form-control"
                  id="email"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="mobileno" className="form-label">
                  Mobile
                </label>
                <input
                  type="text"
                  onChange={handleState}
                  value={selecteduser.mobileno}
                  className="form-control"
                  id="mobileno"
                />
              </div>
              <button type="submit" className="text-light btn btn-info">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        id="popup-model"
        className="d-none align-items-center p-2"
        style={{
          position: "fixed",
          inset: "0",
          background: "rgba(0,0,0,.5)",
          zIndex: "1050",
        }}
      >
        <div
          style={{ position: "fixed", inset: "0", zIndex: "-1" }}
          onClick={popupModelClose}
        ></div>
        <div
          className="container rounded bg-white py-1 px-1 px-sm-5 sizedbox"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="card-header">
            <h3> Delete User </h3>
          </div>
          <div className="card-body flex-container">
            <button
              type="button"
              className="btn btn-info"
              onClick={popupModelClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => deleteuserfun(userid)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className="col-sm-12 ">
        <div className="card pb-2 border-0">
          <div className=" bg-white card-header ">
            <div className="card-title fw-bold fs-4 m-2 ">
              <div className="flex-fill d-flex align-items-center">
                User List
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-fill border-0 bg-transparent fs-4 fw-semibold ps-3 bg-danger"
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-borderless mt-3 ms-4 text-secondary">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Profile</th>
                  <th>Email</th>
                  <th>Date & Time</th>
                  <th>Mobile Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredUserList
                  .slice(
                    (currentPage - 1) * usersPerPage,
                    currentPage * usersPerPage
                  )
                  .map((e, i) => (
                    <tr key={e._id}>
                      <th>{getSerialNumber(i)}.</th>
                      <td className="fw-semibold text-dark">{e.first_name}</td>
                      <td>{e._id}</td>
                      <td>{e.email}</td>
                      <td>{new Date(e.createdAt).toLocaleString()}</td>
                      <td>{e.mobileno}</td>
                      <td>
                        <a
                          className="text-decoration-none bg-info text-white rounded me-2 btn"
                          style={{ padding: "5px 10px", cursor: "pointer" }}
                          onClick={() => editModelOpen(e)}
                        >
                          Edit
                        </a>
                        <a
                          className="text-decoration-none bg-danger text-white rounded"
                          style={{ padding: "5px 10px", cursor: "pointer" }}
                          onClick={() => {
                            setuserid(e._id);
                            popupModelOpen();
                          }}
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center p-3">
          <button
            className="btn btn-secondary mx-3 bg-info border-info"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {renderPagination()}
          <button
            className="btn btn-secondary mx-3 bg-info border-info"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
