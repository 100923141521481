import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotUser } from "../store/slices/userSlice";
import Spinner from "../components/Spinner";
import FontAwesome from "react-fontawesome";
import Header from "../components/Header";

export default function ForgotPassword() {
  const forgotUserStatus = useSelector((state) => state.user.status);
  const [loginData, setLoginData] = useState({
    email: null,
  });
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const handleState = (e) => {
    let temp = { ...loginData };
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    temp[key] = value;
    setLoginData(temp);
  };
  const handleSendLink = (e) => {
    try {
      e.preventDefault();
      let temp = { ...loginData };
      const { email } = temp;
      if (!email) {
        toast.warn("Email is missing");
        return;
      }
      dispatch(forgotUser(temp));
    } catch (err) {
      toast["error"]("Wrong Email or Password.");
      console.log("handleOtp ::::::::::: ", err);
    }
  };
  useEffect(() => {
    if (forgotUserStatus === "success") {
      navigate("/");
    }
  }, [forgotUserStatus]);

  return (
    <div className=" container overflow-hidden ">
      <Header> </Header>
      <div
        className="container pb-4"
        style={{ maxWidth: "516px", marginBottom: "50px", marginTop: "250px" }}
      >
        <h2 className="fw-bold ps-3 mb-4"> Enter Email </h2>
        <div className="d-flex flex-nowrap">
          <form
            className="rounded shadow col-12 p-4"
            onSubmit={(e) => {
              handleSendLink(e);
            }}
          >
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-dark fs-5">
                Email
              </label>
              <input
                type="email"
                onChange={(e) => {
                  handleState(e);
                }}
                className="form-control py-3 fs-5"
                id="email"
                placeholder="yourmail@gmail.com"
              />
            </div>
            <div className="d-flex flex-column align-items-center py-3">
              <div
                role="button"
                className="rounded bg-info text-white py-2 px-4 fs-5 fw-semibold mb-3"
              >
                {" "}
                {forgotUserStatus !== "success" ? (
                  <button
                    onClick={(e) => {
                      handleSendLink(e);
                    }}
                    className="none"
                  >
                    {forgotUserStatus === "loading" ? (
                     <Spinner/>
                    ) : (
                      "Send"
                    )}
                  </button>
                ) : (
                  <FontAwesome
                    role="button"
                    className="fa-solid fa-check pt-2 fa-lg"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
