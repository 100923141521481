import React, { useEffect, useState } from "react";
import { useAxios } from "../../utils/axiosprovider";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./UserList.css"; // Optional: Add a separate CSS file for custom styles.

export default function UsersList() {
  const { requestList, getrequestlist, fulfillUserRequest } = useAxios();
  const usersPerPage = 9;
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState("");
  const [filteredRequestList, setFilteredRequestList] = useState([]);
  const [expandedDetails, setExpandedDetails] = useState({});

  useEffect(() => {
    setFilteredRequestList(
      requestList.filter((request) =>
        request.animationTitle.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [requestList, searchQuery]);

  useEffect(() => {
    getrequestlist();
  }, [getrequestlist]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);
  const totalPages = Math.ceil(filteredRequestList.length / usersPerPage);
  const searchRequest = filteredRequestList;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const paginationItems = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`btn btn-secondary mx-1 bg-info border-info ${
              i === currentPage ? "active bg-danger border-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      const startPage = Math.max(
        1,
        Math.min(
          currentPage - Math.floor(maxVisiblePages / 2),
          totalPages - maxVisiblePages + 1
        )
      );
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (startPage > 1) {
        paginationItems.push(
          <button
            key={1}
            className={`btn btn-secondary mx-1 bg-info border-info ${
              1 === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
        if (startPage > 2) {
          paginationItems.push(
            <span key="ellipsis-start" className="mx-2">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`btn btn-secondary mx-2 bg-info border-info ${
              i === currentPage ? "active border-danger bg-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push(
            <span key="ellipsis-end" className="mx-1">
              ...
            </span>
          );
        }
        paginationItems.push(
          <button
            key={totalPages}
            className={`btn btn-secondary mx-2 bg-info border-info ${
              totalPages === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
      }
    }
    return paginationItems;
  };

  const getSerialNumber = (index) => {
    return (currentPage - 1) * usersPerPage + index + 1;
  };

  const [selectedRequest, setRequest] = useState({
    id: "",
    animationTitle: "",
    email: "",
    mobileno: "",
    animationUrl: "",
    status: "",
  });

  const handleState = (e) => {
    const { id, value } = e.currentTarget;
    setRequest((prev) => ({ ...prev, [id]: value }));
  };

  const statusBadgeClass = (status) => {
    switch (status) {
      case "pending":
        return "badge bg-warning text-dark";
      case "fulfilled":
        return "badge bg-success text-white";
      case "in-progress":
        return "badge bg-info";
      default:
        return "badge bg-secondary";
    }
  };

  const toggleDetails = (userId) => {
    setExpandedDetails((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  const handleEditClick = (request) => {
    setRequest({
      animationTitle: request.animationTitle,
      email: request.email,
      id: request._id,
      animationUrl: request.animationUrl || "",
      status: request.status,
    });
  };

  const handleSaveChanges = async () => {
    console.log("Selected Request before save:", selectedRequest);
    setLoading(true);
    try {
      await fulfillUserRequest(
        selectedRequest.id,
        selectedRequest.animationUrl
      );
      getrequestlist();
    } catch (error) {
      console.error("Error fulfilling request", error);
      alert("Failed to fulfill request. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Ensure currentPage is within the valid range
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    } else if (currentPage < 1) {
      setCurrentPage(1);
    }
  }, [totalPages]);
  return (
    <div className="content-wrapper px-5 text-secondary mt-5">
      {/* Request Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Request
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-2">
                  <label htmlFor="fullName" className="form-label">
                    Animation Title
                  </label>
                  <input
                    type="text"
                    value={selectedRequest.animationTitle} // Default value set here
                    className="form-control text-dark"
                    id="fullName"
                    readOnly
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    onChange={handleState}
                    value={selectedRequest.email}
                    className="form-control"
                    id="email"
                    readOnly
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="animationUrl" className="form-label">
                    Animation URL
                  </label>
                  <input
                    type="text"
                    onChange={handleState}
                    value={selectedRequest.animationUrl}
                    className="form-control"
                    id="animationUrl"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <select
                    id="status"
                    value={selectedRequest.status}
                    onChange={handleState}
                    className="form-select"
                  >
                    <option value="pending">Pending</option>
                    <option value="in-progress">In Progress</option>
                    <option value="fulfilled">Fulfilled</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveChanges}
                  disabled={loading}
                  data-bs-dismiss={!loading ? "modal" : ""}
                >
                  {loading ? "Saving..." : "Save changes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Request List Cards */}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="fw-bold">Request List</h4>
          <input
            type="text"
            // value={searchQuery}
            // onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchQuery(e.target.value);
              } else {
              }
            }}
            className="form-control w-25"
            placeholder="Search requests..."
          />
        </div>

        <div className="row">
          {searchRequest
            .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage)
            .map((user, index) => (
              <div key={user._id} className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-header bg-primary text-light">
                    <h5 className="mb-0">
                      {getSerialNumber(index)}. {user.fullName}
                    </h5>
                  </div>
                  <div className="card-body">
                    <p>
                      {" "}
                      <b> Title:</b> {user.animationTitle}
                    </p>
                    <p>
                      {" "}
                      <b> Mobile:</b> {user.mobileno}
                    </p>
                    <p>
                      <b> Color Scheme:</b>{" "}
                      {user.colorScheme && user.colorScheme.trim() !== ""
                        ? user.colorScheme
                        : "N/A"}
                    </p>
                    <p>
                      <b> Request Type:</b> {user.animationType}
                    </p>
                    <p>
                      <b>ReferenceFile File:</b>{" "}
                      <a
                        href={user.referenceFile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    </p>
                    <p className="card-text">
                      <b> Additional Details:</b>{" "}
                      {user.additionalDetails &&
                      user.additionalDetails.length > 30 ? (
                        expandedDetails[user._id] ? (
                          <>
                            {user.additionalDetails}{" "}
                            <button
                              className="btn btn-link p-0"
                              onClick={() => toggleDetails(user._id)}
                            >
                              ...Less
                            </button>
                          </>
                        ) : (
                          <>
                            {user.additionalDetails.substring(0, 30)}
                            <button
                              className="btn btn-link p-0"
                              onClick={() => toggleDetails(user._id)}
                            >
                              ...More
                            </button>
                          </>
                        )
                      ) : (
                        user.additionalDetails || "N/A"
                      )}
                    </p>

                    <p>
                      <b> Requested Date:</b>{" "}
                      {new Date(user.createdAt).toLocaleString("en-IN", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                    {user.animationUrl && (
                      <a
                        href={user.animationUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-info btn-sm text-light"
                      >
                        View Animation
                      </a>
                    )}
                  </div>
                  <div className="card-footer d-flex justify-content-between">
                    <span
                      className={`${statusBadgeClass(
                        user.status
                      )} align-self-center p-2`}
                      style={{ fontSize: "1rem" }}
                    >
                      {user.status.toUpperCase()}
                    </span>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => handleEditClick(user)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="col-12 d-flex justify-content-center p-3">
            <button
              className="btn btn-secondary mx-3 bg-info border-info"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            {renderPagination()}
            <button
              className="btn btn-secondary mx-3 bg-info border-info"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
