import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function AboutUs() {
  const [paragraph, setParagraph] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const editorRef = useRef(null);

  useEffect(() => {
    // Fetch the content for the video page from the backend
    fetch(`/api/v1/get_Aboutus`)
      .then((response) => response.json())
      .then((data) => {
        setParagraph(data.paragraph);
        setIsLoading(false);
      });
  }, []);

  const handleEditorChange = (content) => {
    setParagraph(content);
  };

  const handleSave = () => {
    // Save the updated paragraph content
    fetch(`/api/v1/add_abouttus`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paragraph }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Content saved successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <div className="content-wrapper mt-5 px-5">
      <Editor
        apiKey="al1t1x9xud3ocjdhmwe9zukumvua5vkaiahq8y6e7vv6eckg"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "a11ychecker",
            "advlist",
            "advcode",
            "advtable",
            "autolink",
            "checklist",
            "export",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "powerpaste",
            "fullscreen",
            "formatpainter",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />

      <div className="d-flex justify-content-end py-2 col-12">
        <div className="btn bg-info text-white fw-semibold" role="button">
          {" "}
          Save{" "}
        </div>
      </div>
    </div>
  );
}
