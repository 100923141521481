import React, { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { useAxios } from "../../utils/axiosprovider";
import { useDispatch, useSelector } from "react-redux";
import { getAllBackgrounds } from "../../store/slices/videoSlice";
export default function BackgroundManagemant() {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [name, setname] = useState("");
  const [mp4Link, setMp4Link] = useState("");
  const [id, setId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { allBackgrounds, totalPages } = useSelector((state) => state.video);
  const [currentPage, setCurrentPage] = useState(1);
  const renderPagination = () => {
    const paginationItems = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`btn btn-secondary mx-1 bg-info border-info ${
              i === currentPage ? "active bg-danger border-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      const startPage = Math.max(
        1,
        Math.min(
          currentPage - Math.floor(maxVisiblePages / 2),
          totalPages - maxVisiblePages + 1
        )
      );
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (startPage > 1) {
        paginationItems.push(
          <button
            key={1}
            className={`btn btn-secondary mx-1 bg-info border-info ${
              1 === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
        if (startPage > 2) {
          paginationItems.push(
            <span key="ellipsis-start" className="mx-2">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <button
            key={i}
            className={`btn btn-secondary mx-2 bg-info border-info ${
              i === currentPage ? "active border-danger bg-danger" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push(
            <span key="ellipsis-end" className="mx-1">
              ...
            </span>
          );
        }
        // paginationItems.push(
        //   <button
        //     key={totalPages}
        //     className={`btn btn-secondary mx-2 bg-info border-info ${
        //       totalPages === currentPage ? "active" : ""
        //     }`}
        //     onClick={() => handlePageChange(totalPages)}
        //   >
        //     {totalPages}
        //   </button>
        // );
      }
    }
    return paginationItems;
  };
  useEffect(() => {
    dispatch(getAllBackgrounds({ page: currentPage }));
  }, [dispatch, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };
  const handleForm = (e) => {
    try {
      e.preventDefault();
      updatebyId(id, {
        name: name,
        tags: tags.map((tag) => tag.text),
        "color.location": mp4Link,
      });
      editModelClose();
    } catch (err) {
      console.log("handleForm error: ", err);
    }
  };
  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  const { deletebyId, updatebyId } = useAxios();

  return (
    <div className="content-wrapper px-5 text-secondary mt-5">
      <div
        id="edit-model"
        className="d-none align-items-center p-2"
        style={{
          position: "fixed",
          inset: "0",
          background: "rgba(0,0,0,.5)",
          zIndex: "1050",
        }}
      >
        <div
          style={{ position: "fixed", inset: "0", zIndex: "-1" }}
          onClick={editModelClose}
        ></div>
        <div className="container rounded bg-white col-4 py-3 px-sm-5">
          <div className="card-header">
            <h3> Edit Object </h3>
          </div>
          <div className="card-body">
            <form
              className="fs-5"
              onSubmit={(e) => {
                handleForm(e);
              }}
            >
              <div className="mb-2">
                <label for="name" className="form-label">
                  Name
                </label>
                {/* <input
                  type="text"
                  className="form-control"
                  value={id}
                  id="id"
                  aria-describedby="id"
                /> */}
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setname(e.currentTarget.value);
                  }}
                  value={name}
                  id="name"
                  aria-describedby="emailHelp"
                />
              </div>{" "}
              <div className="mb-2">
                <label htmlFor="mp4Link" className="form-label">
                  MP4 Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setMp4Link(e.currentTarget.value)}
                  value={mp4Link}
                  id="mp4Link"
                />
              </div>
              <label for="exampleInputEmail1" className="form-label">
                Tags
              </label>
              <div className="mb-2 border border-2 p-1 rounded">
                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="inline"
                  autocomplete
                  editable
                  classNames={{
                    // tags: '',
                    tagInput: "flex-fill d-flex align-items-center",
                    tagInputField: "col-8 rounded",
                    selected: "d-flex flex-wrap flex-fill col-12",
                    tag: "mt-1 mb-1 bg-info rounded ms-1 me-1 ps-3 pe-2 d-flex align-items-center text-white",
                    remove: "ms-2 bg-transparent border-0 text-white fw-bold",
                    suggestions: "suggestionsClass",
                    activeSuggestion: "activeSuggestionClass",
                    editTagInput: "mt-1 mb-1",
                    editTagInputField: "",
                    clearAll: "clearAllClass",
                  }}
                />
              </div>
              <button
                type="submit"
                className="btn btn-info text-light fs-5 my-2"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className="card pb-2 border-0">
          <div className=" bg-white card-header ">
            <div className="card-title fw-bold fs-4 m-2 ">
              <div className="flex-fill d-flex align-items-center">
                Character List
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-fill border-0 bg-transparent fs-4 fw-semibold ps-3 bg-danger"
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover mt-2 table-borderless ms-4 text-secondary">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Tags</th>
                  <th>No. of Downloads</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allBackgrounds.length > 0 &&
                  allBackgrounds.map((e, i) => {
                    return (
                      <tr id={e._id}>
                        <th>{i + 1}</th>
                        <td className="fw-semibold text-dark">{e.name}</td>
                        <td className="w-50">
                          {e.tags.map((tag) => (
                            <>{tag + ","}</>
                          ))}
                        </td>
                        <td>{}</td>
                        <td>
                          <a
                            className="text-decoration-none bg-info text-white rounded me-2"
                            style={{ padding: "5px 10px", cursor: "pointer" }}
                            onClick={() => editModelOpen(e)}
                          >
                            {" "}
                            Edit{" "}
                          </a>
                          <a
                            className="text-decoration-none bg-danger text-white rounded"
                            style={{ padding: "5px 10px", cursor: "pointer" }}
                            onClick={() => deletebyId(e._id)}
                          >
                            {" "}
                            Delete{" "}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center   p-3">
          <button
            className="btn btn-secondary mx-3 bg-info border-info"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {renderPagination()}
          <button
            className="btn btn-secondary mx-3 bg-info border-info"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function editModelClose() {
    document.getElementById("edit-model").classList.remove("d-flex");
    document.getElementById("edit-model").classList.add("d-none");
    // document.getElementById('video-src').src()
  }

  function editModelOpen(e) {
    var list = e.tags.map((element) => {
      return { id: element, text: element };
    });
    setId(e._id);
    setTags(list);
    setname(e.name);
    setMp4Link(e.color.location ?? "");

    document.getElementById("edit-model").classList.add("d-flex");
    document.getElementById("edit-model").classList.remove("d-none");
  }
}
