import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Layout.css";
import Logo from "../images/Logo.svg";

import AdminLogIn from "../pages/AdminLogIn";
import { useAxios } from "../utils/axiosprovider";
import FontAwesome from "react-fontawesome";
import { Helmet } from "react-helmet";

export default function AdminLayout(props) {
  <Helmet>
    <meta name="robots" content="noindex, nofollow" />
  </Helmet>;
  function logout() {
    localStorage.clear();
    window.location.reload(false);
  }

  let { isadmin, deleteuser } = useAxios();
  useEffect(() => {
    // console.log(isadmin);

    return () => {};
  }, []);
  return (
    <>
      {!isadmin && <AdminLogIn />}

      {isadmin && (
        <div>
          <div className="w-100 vertical-layout theme-info navbar-floating">
            <div
              className="d-xl-none d-block vh-100"
              style={{
                touchAction: "pan-y",
                userSelect: "none",
                WebkitUserDrag: "none",
                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
              }}
            ></div>
            <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow theme-info collapsed">
              <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                  <li className="nav-item mr-auto">
                    <a
                      aria-current="page"
                      className="navbar-brand active"
                      href="#/"
                    >
                      <h2 className="text-color fw-bold fs-4 mb-0 me-2">
                        Pixominia-Admin
                      </h2>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="toggle-icon icon-x d-none d-xl-block font-medium-4 text-color"
                        data-tour="toggle-icon"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="shadow-bottom d-none"></div>
              </div>
              <div className="scrollbar-container main-menu-content overflow-hidden ps ps--active-y">
                <ul
                  className="navigation navigation-main"
                  style={{
                    touchAction: "pan-y",
                    userSelect: "none",
                    WebkitUserDrag: "none",
                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                  }}
                >
                  <li className="nav-item active">
                    <Link
                      to="/admin"
                      className="d-flex justify-content-start text-decoration-none"
                    >
                      <div className="menu-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        <span>Dashboard</span>
                      </div>
                    </Link>
                  </li>
                  <li className="navigation-header">
                    <span>Component</span>
                  </li>
                  <li className="nav-item has-sub">
                    <a
                      className="d-flex justify-content-start text-decoration-none"
                      role="button"
                      onClick={toggleParent}
                    >
                      <div className="menu-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span className="menu-item menu-title">
                          User Management{" "}
                        </span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="menu-toggle-icon"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </a>
                    <ul className="menu-content">
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="/admin/userList"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="9" cy="7" r="4"></circle>
                              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                            </svg>
                            <span className="menu-item menu-title">
                              Users List
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="/admin/object-management"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Object Management
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="/admin/background-management"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Background Management
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="/admin/character-management"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Character Management
                            </span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="navigation-header d-none">
                    <span>Object Management</span>
                  </li>
                  <li className="nav-item has-sub d-none">
                    <a
                      href="#/"
                      className="text-decoration-none d-flex justify-content-start"
                    >
                      <div className="menu-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                          <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                          <line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                        <span className="menu-item menu-title">
                          Object Management
                        </span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="menu-toggle-icon"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </a>
                    <ul className="menu-content">
                      <li className="">
                        <a
                          className="text-decoration-none"
                          href="#/app/productmanager/categorylist"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Category List
                            </span>
                          </div>
                        </a>
                      </li>
                      <li className="">
                        <a className="" href="#/app/productmanager/productlist">
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Object List
                            </span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="navigation-header d-none">
                    <span>CMS</span>
                  </li>

                  <li className="nav-item has-sub">
                    <a
                      href="#/"
                      className=" text-decoration-none d-flex justify-content-start"
                      onClick={toggleParent}
                    >
                      <div className="menu-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="12" r="3"></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        <span className="menu-item menu-title">
                          Requests Management
                        </span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="menu-toggle-icon"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </a>
                    <ul className="menu-content">
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="request-list"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Requests List
                            </span>
                          </div>
                        </Link>
                      </li>
                      {/* <li className="">
                        <Link
                          className="text-decoration-none"
                          to="pageSetup/helpUs"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Help Us
                            </span>
                          </div>
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li className="nav-item has-sub">
                    <a
                      href="#/"
                      className=" text-decoration-none d-flex justify-content-start"
                      onClick={toggleParent}
                    >
                      <div className="menu-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="12" r="3"></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        <span className="menu-item menu-title">
                          Page Setup{" "}
                        </span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="menu-toggle-icon"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </a>
                    <ul className="menu-content">
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="pageSetup/contactUs"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Contact Us
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="pageSetup/aboutUs"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              About Us
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="pageSetup/termsConditions"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Terms And Conditions
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="pageSetup/privacyPolicy"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Privacy Policy
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          className="text-decoration-none"
                          to="pageSetup/helpUs"
                        >
                          <div className="menu-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                            <span className="menu-item menu-title">
                              Help Us
                            </span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div
                  className="ps__rail-x"
                  style={{ left: "0px", bottom: "0px" }}
                >
                  <div
                    className="ps__thumb-x"
                    tabIndex="0"
                    style={{ left: "0px", width: "0px" }}
                  ></div>
                </div>
                <div
                  className="ps__rail-y"
                  style={{ top: "0px", right: "0px", height: "526px" }}
                >
                  <div
                    className="ps__thumb-y"
                    tabIndex="0"
                    style={{ top: "0px", height: "233px" }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="app-content content">
              <header className="pt-5">
                <div className="container-fluid px-5 d-flex justify-content-between col-12 align-items-center">
                  <Link to="/">
                    <img src={Logo} style={{ height: "44px" }} alt="" />
                  </Link>
                  <div className="d-flex">
                    <>
                      <Link
                        onClick={logout}
                        role="button"
                        to="/login"
                        className="text-decoration-none mx-3 py-2 px-2 px-sm-3 bg-color rounded text-white fw-semibold"
                      >
                        Log Out
                      </Link>
                    </>
                  </div>
                </div>
              </header>
              <Outlet />
            </div>
            <footer className="footer footer-light footer-static d-flex justify-content-between">
              <p className="mb-0 clearfix">
                <span className="float-md-left d-block d-md-inline-block mt-25">
                  © 2024
                  <a
                    href="https://Pixominia.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PIXOMINIA,
                  </a>
                  All rights reserved
                </span>
                <span className="float-md-right d-none d-md-block"></span>
              </p>
              <div>
                Made with{" "}
                <FontAwesome name="heart" className="fa-solid fa-heart" /> by{" "}
                <a
                  className="fw-semibold"
                  target="blank"
                  href="https://www.pixominia.com/"
                >
                  Pixominia{" "}
                </a>
              </div>
            </footer>
            <div className="sidenav-overlay"></div>
          </div>
          <div
            id="rbd-announcement-0"
            aria-live="assertive"
            role="log"
            aria-atomic="true"
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              margin: "-1px",
              border: "0px",
              padding: "0px",
              overflow: "hidden",
              clip: "rect(0px, 0px, 0px, 0px)",
              clipPath: "inset(100%)",
            }}
          ></div>
          <div id="rbd-lift-instruction-0" style={{ display: "none" }}>
            Draggable item. Ensure your screen reader is not in browse mode and
            then press space bar to lift.
          </div>
        </div>
      )}

      {!isadmin && <div></div>}
    </>
  );

  function toggleParent(e) {
    if (e.target.closest("li").classList.contains("open"))
      e.target.closest("li").classList.remove("open");
    else e.target.closest("li").classList.add("open");
  }
}
