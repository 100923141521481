import React, { useState } from "react";
import MenThinking from "../images/creative.svg";
import { useDispatch, useSelector } from "react-redux";
import { submitRequest } from "../../src/store/slices/requestSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";

export default function RequestNeed() {
  const dispatch = useDispatch();
  const requestDataLoader = useSelector(
    (state) => state.request.requestDataLoader
  );
  const userArray = JSON.parse(window.localStorage.getItem("user"));
  // const user = Array.isArray(userArray) ? userArray[0] : {};
  const firstName = userArray?.first_name || "Guest";
  const email = userArray?.email || "No email provided";
  const mobileno = userArray?.mobileno || "No mobileno. provided";
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: firstName || "",
    email: email || "",
    mobileno: mobileno || "",
    animationTitle: "",
    animationType: "Character",
    colorScheme: " ",
    referenceFile: null,
    additionalDetails: "",
    urgencyLevel: "Normal",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Handle reference file
    if (name === "referenceFile" && files[0]) {
      const file = files[0];
      const fileSizeMB = file.size / (1024 * 1024);

      // Validate file size and type
      if (fileSizeMB > 20) {
        toast.error("File size exceeds 20 MB. Please upload a smaller file.");
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        referenceFile: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.animationTitle.trim()) {
      toast.error("Animation Title should not be empty.");
      return;
    }

    if (!formData.referenceFile) {
      toast.error("Please upload a reference file.");
      return;
    }

    try {
      const requestData = new FormData();
      for (const key in formData) {
        if (key === "referenceFile" && formData[key]) {
          requestData.append(key, formData[key]);
        } else {
          requestData.append(key, formData[key]);
        }
      }

      const resultAction = await dispatch(submitRequest(requestData));

      if (submitRequest.fulfilled.match(resultAction)) {
        toast.success("Request Submitted Successfully");
        navigate("/thank-you");
      } else {
        toast.error(
          resultAction.payload || "Failed to submit request. Please try again."
        );
      }
    } catch (err) {
      console.log("Error in handleSubmit:", err);
      toast.error("An error occurred while submitting the request.");
    }
  };
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-6 align-items-center">
          <div className="row">
            <h1 className="display-5 text-center fw-bold text-info mb-4">
              Request Your Need
            </h1>
            <p className="text-muted mb-4 fs-5">
              Can't find what you're looking for? Let us know your requirements,
              and we'll create the perfect animation or design for you within 24
              hours. Fill out the form below, and our team will get to work!
            </p>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <img
              src={MenThinking}
              style={{ height: "auto", width: "500px" }}
              alt="Thinking Man"
              className="img-fluid rounded-circle border border-2 border-info"
            />
          </div>
        </div>

        <div className="col-6 mt-5">
          <div className="col-md-8 offset-md-2">
            <div className="card p-4 shadow-sm">
              <h3 className="card-title text-center text-info">
                Tell Us What You Need
              </h3>
              <form className="mt-4" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">
                    Animation Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Animation Title"
                    name="animationTitle"
                    value={formData.animationTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Animation Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    name="animationType"
                    value={formData.animationType}
                    onChange={handleChange}
                  >
                    <option value="Character">Character</option>
                    <option value="Icon">Icon</option>
                    <option value="Background">Background</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Color Scheme (Optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mention specific colors or themes"
                    name="colorScheme"
                    value={formData.colorScheme}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Upload Reference Files{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="referenceFile"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Additional Details (Optional)
                  </label>
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="Any extra information or special instructions"
                    name="additionalDetails"
                    value={formData.additionalDetails}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Urgency Level</label>
                  <select
                    className="form-select"
                    name="urgencyLevel"
                    value={formData.urgencyLevel}
                    onChange={handleChange}
                  >
                    <option value="Normal">Normal (48 hours)</option>
                    <option value="Express">Express (36 hours)</option>
                    <option value="Super Express">
                      Super Express (24 hours)
                    </option>
                  </select>
                </div>

                <div
                  role="button"
                  className="rounded bg-info text-white py-2 px-4 fs-5 fw-semibold mb-3"
                >
                  {requestDataLoader ? (
                    <Spinner />
                  ) : (
                    <button type="submit" className="none">
                      Submit Request
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
