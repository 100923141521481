import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoActions } from "../../store";
import { uploadSingleFile, addVideo } from "../../store/slices/videoSlice";
import { toast } from "react-toastify";
import { useAxios } from "../../utils/axiosprovider";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { videoObj } = useSelector((state) => state.video);
  const tags = videoObj.tags;
  const [colorVid, setColorVid] = useState(false);
  const [colorGif, setColorGif] = useState(false);

  let {
    fetchdashboarddata,
    totalUser,
    totalObject,
    totalCharacter,
    totalRequest,
    fulfillRequest,
    pendingRequest,
    activeuser,
    inactiveuser,
    totalBackground,
  } = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      await fetchdashboarddata();
    };
    fetchData();
  }, []);

  function handleName(e) {
    dispatch(videoActions.setNameVideoForObj({ name: e.currentTarget.value }));
  }
  const handleTagInput = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      let inputValue = e.target.value.trim();

      let tagsToAdd = inputValue
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag.length > 0 && !tags.includes(tag)); // Avoid empty or duplicate keywords

      if (tagsToAdd.length > 0) {
        dispatch(
          videoActions.setTagsForVideoObject({ tags: [...tags, ...tagsToAdd] })
        );
      }

      e.target.value = ""; // Clear the input field
    }
  };
  const handleTagRemove = (index) => {
    const updatedTags = tags.filter((_, i) => i !== index); // Remove the tag by index
    dispatch(videoActions.setTagsForVideoObject({ tags: updatedTags }));
  };
  function handleFormSubmit(e) {
    e.preventDefault();
    dispatch(videoActions.setTagsForVideoObject({ tags: tags }));
    try {
      let temp = { ...videoObj };
      let objKeys = Object.keys(temp);
      let message = `fields should not be empty`;
      for (let i in objKeys) {
        let item = temp[objKeys[i]];
        if (typeof item === "string") {
          if (item === "") {
            return toast.error(message);
          }
        }
      }
      let prom = dispatch(addVideo(temp));
      prom.then((status) => {
        if (status.meta.requestStatus == "fulfilled") {
          toast.success(
            `${
              document.getElementById("uploadTypeI").value
            } uploaded successfully`
          );
        }
      });
      resetModal();
      uploadModelClose();
    } catch (err) {
      console.log("error in handleFormSubmit ::: ", err);
    }
  }
  function uploadModelOpen(x) {
    document.getElementById("uploadTypeI").value = x;
    dispatch(videoActions.setTypeForVideoObj({ type: x }));
    document.getElementById("uploadTypeH").innerHTML = x;
    document.getElementById("upload-model").classList.add("d-flex");
    document.getElementById("upload-model").classList.remove("d-none");
  }
  function uploadModelClose() {
    dispatch(videoActions.resetVideoObj());
    document.getElementById("upload-model").classList.remove("d-flex");
    document.getElementById("upload-model").classList.add("d-none");
  }
  function resetModal() {
    const allFileInput = document.querySelectorAll('[type="file"]');
    document.querySelector("#name").value = "";
    Array.from(allFileInput).map((elem) => (elem.value = ""));
  }
  const handleUploadFile = async (e) => {
    try {
      let requestedFile = e.currentTarget.getAttribute("data-filetype");
      let vType = e.currentTarget.getAttribute("data-upload-type");
      let color = e.currentTarget.getAttribute("data-color");

      // Set appropriate loading states
      if (color === "color") {
        if (vType === "imagegif") {
          setColorGif(true);
        } else {
          setColorVid(true);
        }
      }

      let formData = new FormData();
      let nameArr = e.target.files[0].name.split(".");
      let fileTypeToUpload = nameArr[1];
      let type = `file/${fileTypeToUpload}`;

      if (e.target.files[0].type === requestedFile || type === requestedFile) {
        // Append file to formData
        formData.append("file", e.target.files[0]);

        // Dispatch upload action
        const result = await dispatch(
          uploadSingleFile({
            formData: formData,
            vtype: vType,
            mainKey: color,
            fileType: fileTypeToUpload,
          })
        );

        // Handle completion of upload
        if (result.meta.requestStatus === "fulfilled") {
          toast.success(`${fileTypeToUpload} uploaded successfully`);
        }

        // Reset loaders based on file type
        if (color === "color") {
          if (vType === "imagegif") {
            setColorGif(false);
          } else {
            setColorVid(false);
          }
        }
      } else {
        toast.error(`Please upload a ${requestedFile.split("/")[1]} file`);
      }
    } catch (err) {
      console.log("Error in handleUploadFile: ", err);
      toast.error("Failed to upload the file");
      setColorGif(false);
      setColorVid(false);
    }
  };

  return (
    <div className="container-fluid px-5 my-5">
      <div
        id="upload-model"
        className="d-none align-items-center py-2 px-5"
        style={{
          position: "fixed",
          inset: "0",
          background: "rgba(0,0,0,.5)",
          zIndex: "1050",
        }}
      >
        <div
          style={{ position: "fixed", inset: "0", zIndex: "-1" }}
          onClick={uploadModelClose}
        ></div>
        <div
          className="container px-4 col-6 rounded bg-white overflow-scroll"
          style={{ maxHeight: "90vh" }}
        >
          <div className=" border-bottom px-2 py-3 fs-4 text-color fw-semibold">
            Upload <span id="uploadTypeH"></span>
          </div>
          <div className="col-12 d-flex flex-wrap px-2">
            <form
              className="col-12 px-0 mb-2"
              onSubmit={(e) => {
                handleFormSubmit(e);
              }}
            >
              <input type="hidden" id="uploadTypeI" />
              <div className="mt-3">
                <label
                  htmlFor="name"
                  className="form-label text-dark fw-semibold"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  onChange={(e) => {
                    handleName(e);
                  }}
                />
              </div>
              <div className="d-flex flex-wrap mt-3">
                <label
                  htmlFor="tags"
                  className="form-label text-dark fw-semibold me-3 "
                >
                  Tags
                </label>
                {(tags || []).map((tag, index) => (
                  <div
                    key={index}
                    className="keyword-tag d-flex align-items-center me-2 mb-2 rounded"
                    style={{ backgroundColor: "#227B94" }}
                  >
                    <span className="badge p-2 me-1">{tag}</span>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      aria-label="Remove"
                      onClick={() => handleTagRemove(index)}
                    ></button>
                  </div>
                ))}

                <input
                  type="text"
                  className="form-control"
                  onKeyDown={handleTagInput}
                  placeholder="Add a Tag and press Enter"
                />
              </div>
              {colorVid ? (
                <div className="spinner-border text-color" role="status">
                  <span className="sr-only">Uploading...</span>
                </div>
              ) : null}
              <div className="my-5 d-flex">
                <input
                  className="form-control col-12"
                  data-color="color"
                  data-filetype="video/mp4"
                  onChange={(e) => {
                    handleUploadFile(e);
                  }}
                  type="file"
                  id="colorVideo"
                />
                <div
                  className="text-nowrap d-flex rounded align-items-center bg-color text-white rounded-right fs-5 px-3"
                  style={{ transform: "translateX(-100%)" }}
                  role="button"
                  onClick={(e) => {
                    e.target.previousSibling.click();
                  }}
                >
                  <i className="bi bi-cloud-arrow-up-fill"></i> Upload Color Mp4
                </div>
              </div>
              {colorGif ? (
                <div className="spinner-border text-color" role="status">
                  <span className="sr-only">Uploading...</span>
                </div>
              ) : null}
              <div className="my-5 d-flex">
                <input
                  className="form-control col-12"
                  type="file"
                  id="formFile"
                  data-upload-type="imagegif"
                  data-color="color"
                  data-filetype="image/gif"
                  onChange={(e) => {
                    handleUploadFile(e);
                  }}
                />
                <div
                  className="text-nowrap d-flex rounded align-items-center bg-color text-white rounded-right fs-5 px-3"
                  style={{ transform: "translateX(-100%)" }}
                  role="button"
                  onClick={(e) => {
                    e.target.previousSibling.click();
                  }}
                >
                  <i className="bi bi-cloud-arrow-up-fill"></i> Upload Color GIF
                </div>
              </div>
              <button
                type="submit"
                color
                className="btn mb-3 fs-5 fw-semibold btn-info text-white"
                disabled={colorGif || colorVid}
              >
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>

      <div
        id="uploadDiv"
        className="d-flex flex-column-reverse overflow-hidden"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "500",
          height: "57px",
          transition: "all 0.3s ease 0s",
        }}
      >
        <div
          className="my-2 px-2 py-1  rounded-pill d-flex justify-content-center fs-5 fw-semibold align-items-center bg-color text-white"
          role="button"
          onClick={toggleUpload}
        >
          <i className="bi bi-upload"></i>{" "}
          <span className="mx-3 py-2"> Upload </span>
        </div>

        <div
          className="my-2 px-2 py-1  rounded-pill d-flex justify-content-center fs-5 fw-semibold align-items-center bg-color text-white"
          role="button"
          onClick={() => uploadModelOpen("Background")}
        >
          <i className="bi bi-upload"></i>{" "}
          <span className="mx-3 py-2"> Background </span>
        </div>

        <div
          className="my-2 px-2 py-1  rounded-pill d-flex justify-content-center fs-5 fw-semibold align-items-center bg-color text-white"
          role="button"
          onClick={() => uploadModelOpen("Transition")}
        >
          <i className="bi bi-upload"></i>{" "}
          <span className="mx-3 py-2"> Character </span>
        </div>

        <div
          className="my-2 px-2 py-1  rounded-pill d-flex justify-content-center fs-5 fw-semibold align-items-center bg-color text-white"
          role="button"
          onClick={() => uploadModelOpen("Object")}
        >
          <i className="bi bi-upload"></i>{" "}
          <span className="mx-3 py-2"> Object </span>
        </div>
      </div>

      <div className="card">
        <div className="fw-semibold mx-4 fs-4 mt-3 mb-0 card-title">Users</div>
        <hr />
        <div className="card-body">
          <div className="match-height row">
            <div className="col-md-4">
              <div className="bg-color py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-total-objects"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                  </span>
                  Total Users
                  <span className="mx-3 fs-3">{totalUser}</span>
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-success py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-total-objects"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                  </span>
                  Active Users
                  <span className="mx-3 fs-3">{activeuser}</span>
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-danger py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-total-objects"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                  </span>
                  Inactive Users
                  <span className="mx-3 fs-3">{inactiveuser}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-4">
        <div className="fw-semibold mx-4 fs-4 mt-3 mb-0 card-title">
          Request
        </div>
        <hr />
        <div className="card-body">
          <div className="match-height row">
            <div className="col-md-4">
              <div className="bg-color py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-request"
                    >
                      <path d="M12 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z"></path>
                      <polyline points="14 2 14 8 20 8"></polyline>
                      <line x1="12" y1="18" x2="12" y2="12"></line>
                      <line x1="9" y1="15" x2="15" y2="15"></line>
                    </svg>
                  </span>
                  Total Request
                  <span className="mx-3 fs-3">{totalRequest}</span>
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-success py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-fulfilled-request"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9 12l2 2 4-4"></path>
                    </svg>
                  </span>
                  FulFill Request
                  <span className="mx-3 fs-3">{fulfillRequest}</span>
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-danger py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-pending-request"
                    >
                      <path d="M6 2h12"></path>
                      <path d="M6 22h12"></path>
                      <path d="M6 2l6 8 6-8"></path>
                      <path d="M6 22l6-8 6 8"></path>
                    </svg>
                  </span>
                  Pending Request
                  <span className="mx-3 fs-3">{pendingRequest}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-4">
        <div className="fw-semibold mx-4 fs-4 mt-3 mb-0 card-title">
          Counting
        </div>
        <hr />
        <div className="card-body">
          <div className="match-height row">
            <div className="col-md-4">
              <div className="bg-color py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-total-objects"
                    >
                      <rect x="3" y="3" width="7" height="7"></rect>
                      <rect x="14" y="3" width="7" height="7"></rect>
                      <rect x="14" y="14" width="7" height="7"></rect>
                      <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                  </span>
                  Total Object
                  <span className="mx-3 fs-3">{totalObject}</span>
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-success py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-total-characters"
                    >
                      <circle cx="12" cy="7" r="4"></circle>
                      <path d="M4 21c0-4.5 4.5-8 8-8s8 3.5 8 8"></path>
                    </svg>
                  </span>
                  Total Character
                  <span className="mx-3 fs-3">{totalCharacter}</span>
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-danger py-2 px-3 rounded">
                <h2 className=" fs-4 fw-bold my-3 text-white">
                  <span className="text-white fw-bold ms-3 me-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-backgrounds"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                      ></rect>
                      <circle cx="8.5" cy="8.5" r="1.5"></circle>
                      <path d="M21 15l-5-5L5 21"></path>
                    </svg>
                  </span>
                  Total Background
                  <span className="mx-3 fs-3">{totalBackground}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function toggleUpload() {
    if (document.getElementById("uploadDiv").style.height == "57px") {
      document.getElementById("uploadDiv").style.height = "384px";
    } else {
      document.getElementById("uploadDiv").style.height = "57px";
    }
  }
}
