import apiClient from "./index";

export async function submitRequest(requestData) {
  try {
    const response = await apiClient.post(
      "/request/submitRequest",
      requestData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error in submitRequest:", err);
    throw err;
  }
}