import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/Logo Black.svg";
import FontAwesome from "react-fontawesome";
import "./Footer.css";

export default function Footer() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  useEffect(() => {
    var user = window.localStorage.getItem("user");

    var usertoken = window.localStorage.getItem("accessToken");
    if (usertoken !== null && usertoken !== "") {
      setisLoggedIn(true);
    }
  }, []);

  return (
    <footer className="text-center text-lg-start bg-light text-muted border-top border-info">
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <div className="mb-3">
                {" "}
                <Link to="/">
                  <img
                    src={Logo}
                    style={{ height: "50px", width: "auto" }}
                    alt="Pixominia Logo"
                  />
                </Link>{" "}
              </div>
              <div className="col mb-2">
                <h3 className="text-uppercase fw-bold mt-4 fs-5">
                  Follow Us On
                </h3>
                <ul
                  style={{
                    margin: "0",
                    padding: "0",
                    display: "flex",
                  }}
                >
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="social-icon facebook"
                      href="https://www.facebook.com/profile.php?id=100091151316495&is_tour_completed=true"
                      rel="noopener"
                      aria-label="Facebook"
                    >
                      <FontAwesome
                        className="fab fa-facebook-f"
                        name="facebook"
                      />
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="social-icon twitter"
                      href="https://twitter.com/Pixominia"
                      rel="noopener"
                      aria-label="Facebook"
                    >
                      <FontAwesome name="twitter" className="fab fa-twitter" />
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="social-icon instagram"
                      href="https://www.instagram.com/pixo.minia/"
                      rel="noopener"
                      aria-label="Facebook"
                    >
                      <FontAwesome
                        name="instagram"
                        className="fab fa-instagram"
                      />
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="social-icon youtube"
                      href="https://www.youtube.com/channel/UCiw8rG8VkdfX1dBPaoULlbA"
                      rel="noopener"
                      aria-label="Facebook"
                    >
                      <FontAwesome
                        name="youtube"
                        className="fab fa-youtube-play"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">QUICK LINKS</h6>
              <p>
                <Link to="/aboutUs" className="text-reset text-decoration-none">
                  {" "}
                  About Us{" "}
                </Link>
              </p>

              {!isLoggedIn ? (
                <p>
                  <Link
                    to="/signup"
                    className="text-reset text-decoration-none"
                  >
                    Register Now
                  </Link>
                </p>
              ) : (
                ""
              )}
              <p>
                <Link
                  to="/termsConditions"
                  className="text-reset text-decoration-none"
                >
                  Terms of Use
                </Link>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                If you want to contact us about any issue our support available
                to help you 8am-7pm Monday to saturday.
              </p>
              <p>Mail: Pixominia@gmail.com</p>
            </div>
          </div>
        </div>
      </section>
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <a
          className="text-reset text-decoration-none"
          href="https://pixominia.com"
        >
          Copyright © 2024 Pixominia All rights reserved.
        </a>
      </div>
    </footer>
  );
}
