import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  getAllObjects,
  getAllTransitions,
  getAllBackgrounds,
  getAllVideos,
  searchVideos,
} from "../store/slices/videoSlice";
import debounce from "lodash.debounce";
import { BACKEND_URL } from "../utils";
import FontAwesome from "react-fontawesome";
import "./Home.css";

export default function Home() {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allObjects, allTransitions, allBackgrounds } = useSelector(
    (state) => state.video
  );
  const { searchedVideos, videoMsg, loading } = useSelector(
    (state) => state.video
  );
  const [tabKey, setTabKey] = useState(0);
  const [selectedColor, setSelectedColor] = useState("color"); // Choose your default color
  const [colorSlctd, setColorSlctd] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Transition");

  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    dispatch(getAllObjects());
    dispatch(getAllVideos());
    dispatch(getAllTransitions());
    dispatch(getAllBackgrounds());
  }, [dispatch]);

  const handleRequestClick = useCallback(() => {
    navigate("/request-your-need");
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  }, [navigate]);

  const handlePageChange = useCallback(
    (name, id) => {
      const formattedName = formatNameForUrl(name);
      navigate(`/video/${formattedName}/${id}`);
    },
    [navigate]
  );

  const formatNameForUrl = (name) =>
    name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "");

  function pageChange() {
    if (selectedTab === "Transition") {
      navigate("/character");
    } else {
      navigate(`/${selectedTab.toLowerCase()}`);
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 500);
  }

  // const debouncedSearch = useCallback(
  //   debounce(({ query, currentTab }) => {
  //     if (query) {
  //       dispatch(searchVideos({ query, currentTab }))
  //         .unwrap()
  //         .then((result) => {
  //           console.log("Videos fetched: ", result);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching videos: ", error);
  //         });
  //     }
  //   }, 250),
  //   [dispatch]
  // );
  const debouncedSearch = useCallback(
    debounce(({ query }) => {
      if (query) {
        dispatch(searchVideos({ query }))
          .unwrap()
          .then((result) => {
            console.log("Videos fetched: ", result);
          })
          .catch((error) => {
            console.error("Error fetching videos: ", error);
          });
      }
    }, 250),
    [dispatch]
  );

  const handleSearch = (searchedKey) => {
    const inputText =
      searchedKey === null
        ? inputRef.current.value.toLowerCase()
        : searchedKey.toLowerCase();
    setSearchKey(inputText);
    // setVideoMsg("Loading...");
    debouncedSearch({ query: inputText });
    window.scrollTo({ top: 450, left: 0, behavior: "smooth" });
  };

  // const debouncedSearch = useCallback(
  //   debounce(async (inputText) => {
  //     try {
  //       const response = await fetch(
  //         `http://localhost:9000/api/v1/searchVideos?query=${inputText}`
  //       );
  //       const data = await response.json();
  //       if (data.status === "success") {
  //         setSearchedVideos(data.data);
  //         setVideoMsg(data.data.length > 0 ? "" : "No Videos");
  //       } else {
  //         setVideoMsg("Error fetching videos");
  //       }
  //     } catch (error) {
  //       setVideoMsg("Error fetching videos");
  //     }
  //   }, 250),
  //   []
  // );

  const hoverInVideo = useCallback((e) => {
    const videoElement = e.currentTarget.getElementsByTagName("video")[0];
    videoElement?.play().catch(() => {});
  }, []);

  const hoverOutVideo = useCallback((e) => {
    const videoElement = e.currentTarget.getElementsByTagName("video")[0];
    if (videoElement && !videoElement.paused) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }, []);
  const itemsToDisplay = (() => {
    switch (selectedTab) {
      case "Object":
        return allObjects;
      case "Transition":
        return allTransitions;
      case "Background":
        return allBackgrounds;
      default:
        return [];
    }
  })();

  const filterBtnClick = useCallback(
    (e) => {
      const tabId = e.currentTarget.id;
      if (tabId !== selectedTab) {
        setSelectedTab(tabId);
        setTabKey((prevKey) => prevKey + 1);
        // handleSearch(searchKey, tabId);
        // console.log("Called")
      }
      setSelectedTab(tabId);
      if (searchKey) {
        console.log("Called");
        handleSearch(searchKey, tabId);
      }
      let buttons = document.getElementsByClassName("filter-btn");
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove("text-color", "bg-white", "border-color");
        buttons[i].classList.add("text-dark");
      }
      e.target.classList.add(
        "text-color",
        "border-color",
        "fw-semibold",
        "bg-white"
      );
      e.target.classList.remove("text-dark");
    },
    [selectedTab, searchedVideos]
  );

  return (
    <div>
      <div className="">
        <Header> </Header>
      </div>
      <div className="container mt-0 mt-lg-4">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 mb-lg-5 mb-5 d-lg-flex">
                <div className="col-12 mt-5 text-center text-lg-start">
                  <h1 className="mb-4 fw-bold">
                    THE EASIEST WAY TO <br />
                    <span className="text-color">ANIMATION</span> <br />
                  </h1>
                  <span className="font2">
                    {" "}
                    Download free{" "}
                    <span className="fw-semibold">transparent</span> videos{" "}
                  </span>
                </div>
              </div>
              <div className="col-12 mt-10">
                <div className="designed-rectangle"></div>
                <div className="col-12 col-md-12 mt-3 mt-lg-5">
                  <div className="d-flex col-lg-10 col-12 px-2 py-3 rounded align-items-center bg-white">
                    <div className="flex-fill d-flex align-items-center">
                      <input
                        className="flex-fill border-0 bg-transparent fs-5 ps-3"
                        ref={inputRef}
                        placeholder="Search..."
                        type="text"
                        // onChange={(e) =>
                        //   handleSearch(null, e.currentTarget.value, null)
                        // }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch(
                              e.currentTarget.value,
                              selectedTab,
                              null
                            );
                          } else {
                          }
                        }}
                      />
                      <label> </label>
                      <i
                        className="fa fa-search fa-lg"
                        onClick={() => handleSearch(null, selectedTab, null)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div
                    className="d-flex col-12 col-lg-10 justify-content-between"
                    style={{ paddingTop: "2rem" }}
                  >
                    <div
                      role="button"
                      className="filter-btn rounded px-3 py-2 fs-5 me-3 text-color border-color fw-semibold bg-white"
                      id="Transition"
                      onClick={(e) => filterBtnClick(e)}
                    >
                      {" "}
                      Character{" "}
                    </div>
                    <div
                      role="button"
                      className="filter-btn rounded px-3 fs-5 py-2 text-dark fw-semibold mx-lg-4 mx-2"
                      id="Object"
                      onClick={(e) => filterBtnClick(e)}
                    >
                      {" "}
                      Object{" "}
                    </div>
                    <div
                      role="button"
                      className="filter-btn rounded px-3 fs-5 py-2 text-dark fw-semibold mx-lg-4 mx-2"
                      id="Background"
                      onClick={(e) => filterBtnClick(e)}
                    >
                      Background
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-6 d-none d-lg-block mt-n1">
            <div
              className="col-10 col-xxl-11 ms-10"
              style={{ backgroundColor: "transparent" }}
            >
              <video
                className="col-12"
                id="mainVideo"
                style={{ width: "100%", background: "transparent" }}
                autoPlay
                loop
                muted
                playsInline
                preload="metadata"
                loading="lazy"
                // poster="https://s3.ap-south-1.amazonaws.com/assets.pixominia.com/03.jpg"
              >
                <source
                  type="video/webm"
                  src={`https://s3.ap-south-1.amazonaws.com/assets.pixominia.com/03.webm`}
                />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 container ">
        <h1 className="text-center mb-0 mb-lg-5 mt-5 mt-lg-0 font3">
          {" "}
          DOWNLOAD AND SAVE YOUR TIME{" "}
        </h1>
        <div className="col-lg-12 d-flex flex-wrap justify-content-evenly">
          {searchKey !== "" || colorSlctd ? (
            <>
              {loading ? (
                <div className="spinner-border text-color" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
              {searchedVideos.map((item, index) => {
                if (selectedColor in item) {
                  return (
                    <div
                      onClick={() => handlePageChange(item.name, item._id)}
                      key={item._id}
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      <div
                        className=" pt-5 p-lg-3 d-flex justify-content-center link-none text-dark"
                        key={index}
                        data-item={JSON.stringify(item)}
                        onMouseOver={hoverInVideo}
                        onMouseOut={hoverOutVideo}
                      >
                        <div className="rounded col-10 col-lg-12 videoPlaceholder">
                          <div>
                            <video
                              className="col-12 pt-3 pt-lg-1 multiVideo"
                              loop
                              muted
                              // playsinline
                              // autoPlay
                              controls={false}
                              key={item}
                            >
                              <source
                                src={
                                  "location" in item[selectedColor]
                                    ? item[selectedColor].location.startsWith(
                                        "https://s3."
                                      )
                                      ? item[selectedColor].location
                                      : item[selectedColor].location
                                          .replace(
                                            "/assets.pixominia.com.",
                                            "/"
                                          )
                                          .replace(
                                            ".com/",
                                            ".com/assets.pixominia.com/"
                                          )
                                    : `${BACKEND_URL}/uploads/${item[selectedColor].filename}`
                                }
                                type="video/mp4"
                              />
                            </video>
                          </div>
                          <div
                            className="d-flex col-12 justify-content-between col-12 py-2 px-2 border-top fs-5"
                            // id="placeholder"
                          >
                            <span className="fw-semibold">
                              {item.name.length > 13
                                ? item.name.slice(0, 13) + ".."
                                : item.name}
                            </span>
                            <FontAwesome
                              name="download"
                              role="button"
                              className="fa-solid fa-download pt-2 fa-lg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              {searchedVideos.length < 1 && !loading && (
                <div>
                  <div
                    onClick={handleRequestClick}
                    className="btn btn-info text-dark btn-lg active"
                    role="button"
                    aria-pressed="true"
                  >
                    Request Your Need
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {itemsToDisplay.length > 0 &&
                itemsToDisplay.map((item, index) => {
                  return (
                    <div
                      // key={tabKey}
                      onClick={() => handlePageChange(item.name, item._id)}
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      <div
                        key={tabKey}
                        className=" pt-5 p-lg-3 d-flex justify-content-center link-none text-dark"
                        data-item={JSON.stringify(item)}
                        onMouseOver={hoverInVideo}
                        onMouseOut={hoverOutVideo}
                      >
                        <div className="rounded col-10 col-lg-12 videoPlaceholder">
                          <video
                            className="col-12 pt-3 pt-lg-1 multiVideo"
                            loop
                            muted
                            // playsinline
                            // autoPlay
                            controls={false}
                            key={item}
                          >
                            <source
                              src={
                                "location" in item[selectedColor]
                                  ? item[selectedColor].location.startsWith(
                                      "https://s3."
                                    )
                                    ? item[selectedColor].location
                                    : item[selectedColor].location
                                        .replace("/assets.pixominia.com.", "/")
                                        .replace(
                                          ".com/",
                                          ".com/assets.pixominia.com/"
                                        )
                                  : `${BACKEND_URL}/uploads/${item[selectedColor].filename}`
                              }
                              type="video/mp4"
                            />
                          </video>
                          <div
                            className="d-flex col-12 justify-content-between col-12 py-2 px-2 border-top fs-5"
                            // id="placeholder"
                          >
                            <span className="fw-semibold">
                              {item.name.length > 13
                                ? item.name.slice(0, 13) + ".."
                                : item.name}
                            </span>
                            <FontAwesome
                              name="download"
                              role="button"
                              className="fa-solid fa-download pt-2 fa-lg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {itemsToDisplay.length < 1 && (
                <h2 className="text-center mb-5"> {videoMsg} </h2>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex my-5 justify-content-center ">
        <div
          role="button"
          onClick={pageChange}
          aria-pressed={true}
          className="text-decoration-none ms-3 py-2 px-3 text-color border-color rounded fw-semibold"
        >
          See All
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
