import './style.css';
import loader from '../../images/loader.gif';
import ClipLoader from "react-spinners/ClipLoader";
function Spinner(){
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "white",
      };
    return <ClipLoader
      color={"white"}
      loading={true}
      cssOverride={override}
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
}

export default Spinner;