import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AxiosProvider } from "./utils/axiosprovider";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";

import MainLayout from "./layout/MainLayout";
import AdminLayout from "./layout/AdminLayout";
import AdminLogIn from "./pages/AdminLogIn";

import NotFound from "./pages/NotFound";
import HOME from "./pages/Home";
import LOGIN from "./pages/Login";
import FORGOTPASSWORD from "./pages/ForgotPassword";
import RESETPASSWORD from "./pages/resetPassword";
import SIGNUP from "./pages/Singup";
import ABOUTUS from "./pages/AboutUs";
import RequestNeed from "./pages/RequestNeed";
import ThankYou from "./pages/ThankYou";
import OBJECTPAGE from "./pages/ObjectPage";
import BACKGROUNDPAGE from "./pages/BackGroundPage";
import TRANSITIONPAGE from "./pages/TransitionPage";
import USERPROFILE from "./pages/userProfile";
import store from "../src/store";
import { Provider } from "react-redux";
// import CREATOR from './pages/Creator';
import VIDEOPAGE from "./pages/VideoPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DASHBOARD from "./pages/admin/Dashboard";
import PROFILE from "./pages/admin/Profile";
import USERLIST from "./pages/admin/UsersList";
import REQUESTLIST from "./pages/admin/RequestList";
import OBJECTMANAGEMENT from "./pages/admin/ObjectManagemant";
import BACKGROUNDMANAGEMENT from "./pages/admin/BackgroundManagemant";
import CharacterManagemant from "./pages/admin/CharacterManagemant";
import PageCONTACTUS from "./pages/admin/pageSetup/ContactUs";
import PageABOUTUS from "./pages/admin/pageSetup/AboutUs";
import PageTERMSCONDITIONS from "./pages/admin/pageSetup/TermsConditions";
import PagePRIVACYPOLICY from "./pages/admin/pageSetup/PrivacyPolicy";
import PageHELPUS from "./pages/admin/pageSetup/HelpUs";
import ProtectedRoute from "./utils/ProtectedRoutes";

export default function App() {
  return (
    <BrowserRouter>
      <AxiosProvider>
        <Routes>
          <Route path="/" element={<HOME />} />
          <Route path="/video/:name/:id" element={<VIDEOPAGE />} />
          <Route path="/object" element={<OBJECTPAGE type="Object" />} />
          <Route
            path="/background"
            element={<BACKGROUNDPAGE type="Background" />}
          />
          <Route
            path="/character"
            element={<TRANSITIONPAGE type="Transition" />}
          />
          <Route
            path="/thank-you"
            element={<ProtectedRoute element={ThankYou} />}
          />
          <Route path="forgot-password" element={<FORGOTPASSWORD />} />
          <Route path="change-password" element={<RESETPASSWORD />} />

          <Route path="/" element={<MainLayout />}>
            <Route path="login" element={<LOGIN />} />
            <Route path="signup" element={<SIGNUP />} />
            <Route path="aboutUs" element={<ABOUTUS />} />
            <Route path="user-profile" element={<USERPROFILE />} />
            <Route path="termsConditions" element={<PageTERMSCONDITIONS />} />
            <Route
              path="request-your-need"
              element={<ProtectedRoute element={RequestNeed} />}
            />
            <Route path="AdminLogin" element={<AdminLogIn />} />
          </Route>

          <Route
            path="/admin"
            element={<AdminLayout displayName="Dashboard" />}
          >
            <Route index element={<DASHBOARD />} />
            <Route path="profile" element={<PROFILE />} />
            <Route path="userList" element={<USERLIST />} />

            <Route path="request-list" element={<REQUESTLIST />} />
            <Route path="object-management" element={<OBJECTMANAGEMENT />} />
            <Route
              path="background-management"
              element={<BACKGROUNDMANAGEMENT />}
            />
            <Route
              path="character-management"
              element={<CharacterManagemant />}
            />

            <Route path="pageSetup/contactUs" element={<PageCONTACTUS />} />
            <Route path="pageSetup/aboutUs" element={<PageABOUTUS />} />
            <Route
              path="pageSetup/termsConditions"
              element={<PageTERMSCONDITIONS />}
            />
            <Route
              path="pageSetup/privacyPolicy"
              element={<PagePRIVACYPOLICY />}
            />
            <Route path="pageSetup/helpUs" element={<PageHELPUS />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AxiosProvider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
);
