import React from "react";
import "./404Page.css"; // Make sure to import the CSS file for custom styles

const NotFound = () => {
  return (
    <div className="error-container text-center d-flex align-items-center justify-content-center">
      <div className="error-content">
        <h1 className="display-1 error-code">
          404<span className="error-subtext">Not Found</span>
        </h1>
        <p className="lead error-message">
          It seems this page is orbiting somewhere else in Pixominia.
        </p>
        <a href="/" className="btn btn-primary home-button">
          Go to homepage
        </a>
      </div>
    </div>
  );
};
export default NotFound;
